import { useMemo, type FC } from 'react';
import type { FormulaValueProps } from '../type';
import { formula } from '@flowus/formula';
import { PersonTag } from '../../../person';
import { arrayToStyle } from '@flowus/common';
import { ar } from 'date-fns/locale';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { cx } from '@flowus/common/cx';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';

export const FormulaRichTextValue: FC<FormulaValueProps> = (props) => {
  const segments = useMemo(() => {
    return formula.ValueTool.asList(props.value).elements.map((v) => {
      const array = v as string[];
      return arrayToStyle(array)[0] as SegmentDTO;
    });
  }, [props.value]);

  return <RichText className={cx('leading-[20px]')} segments={segments} interactable={true} />;
};
export const FormulaSingleRichTextValue: FC<FormulaValueProps> = (props) => {
  const arr = formula.ValueTool.asRichText(props.value);
  const segments = useMemo(() => {
    return arrayToStyle(arr);
  }, [arr]);
  return <RichText className={cx('leading-[20px]')} segments={segments} interactable={true} />;
};
