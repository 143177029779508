import { BlockType } from '@next-space/fe-api-idl';
import { Provider as JotaiProvider } from 'jotai';
import type { FC } from 'react';
import { useContext, useEffect } from 'react';
import { ILLEGAL_TEXT, LAST_VIEW_PAGE } from 'src/common/const';
import { request } from 'src/common/request';
import * as Empty from 'src/components/empty';
import { segmentsToText } from 'src/editor/utils/editor';
import { ReadonlyContext } from 'src/hooks/block/use-block-locked';
import { useFetchPage, useReadonly } from 'src/hooks/page';
import { activeTracker } from 'src/hooks/public/use-report-active';
import { COPY_TO_SPACE_UUID, useCopyToSpace } from 'src/hooks/share/use-copy-to-space';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentSpace } from 'src/hooks/space';
import { useCurrentUser } from 'src/hooks/user';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache, getState } from 'src/redux/store';
import { useSyncUpFault } from 'src/services/app';
import { useGetSyncService } from 'src/services/sync';
import { sequence } from 'src/utils/async-utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { getFirstTextByBlock } from 'src/utils/block-utils';
import { setBrowserTitle } from 'src/utils/emoji-favicon';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { judgeSharePage, useGetMasterPageId, useGetPageId } from 'src/utils/getPageId';
import { setLocalStorage } from 'src/utils/local-storage';
import { usePickBlock } from 'src/utils/pick-block';
import { useIsInRight } from 'src/utils/right-utils';
import { PageBitable } from './page-bitable';
import { PageChart } from './page-chart';
import { PageDoc } from './page-doc';
import { PageDrive } from './page-drive';
import { PageMind } from './page-mind';
import { $pageId, PAGE_SCOPE } from './page-states';
import { PageScene, usePageScene } from './scene-context';
import { usePageMeta } from 'src/hooks/page/use-page-meta';
import { PayAccessFee } from 'src/components/empty/pay-access-fee';

interface PageProps {
  uuid: string;
}

export const Page: FC<PageProps> = (props) => {
  const { uuid, children } = props;
  const block = usePickBlock(
    uuid,
    ['data', 'local', 'subNodes'],
    ['icon', 'segments', 'iconUrl', 'seoDescription', 'seoTitle']
  );
  const pageId = useGetPageId();
  const pageMeta = usePageMeta(pageId);
  const masterPageId = useGetMasterPageId();
  const hasBlock = Boolean(block);
  const sync = useGetSyncService();
  const fetchPage = useFetchPage();
  const readonly2 = useContext(ReadonlyContext);
  const readonly = useReadonly(uuid);
  const syncUpFault = useSyncUpFault();
  const {
    allowDuplicate,
    /** 被风控了 */
    illegal,
  } = usePermissions(uuid);
  const isSharePage = judgeSharePage();
  const isInRight = useIsInRight();
  const currentSpace = useCurrentSpace();
  const currentUser = useCurrentUser();
  const isLogin = Boolean(currentUser.uuid);
  const copyToSpace = useCopyToSpace();
  const isTemplate = usePageScene() === PageScene.TEMPLATE;

  useEffect(() => {
    const copyToSpaceUuid = window.sessionStorage.getItem(COPY_TO_SPACE_UUID);
    if (isSharePage && copyToSpaceUuid === uuid && isLogin) {
      window.sessionStorage.removeItem(COPY_TO_SPACE_UUID);
      copyToSpace(uuid);
    }
  }, [copyToSpace, isSharePage, uuid, isLogin]);

  useEffect(() => {
    if (!uuid) return;
    if (!hasBlock) return;
    if (!isLogin) return; // 没登录的不需要记录
    if (isTemplate) return; // 模版中心的不需要记录

    const block = cache.blocks[uuid];
    if (!block || block.local) return;
    void sequence(async () => {
      if (!readonly) {
        // 防止线上 debug 的时候，看空间会导致被添加到历史记录里，泄露了行为
        if (__HOST_LOCAL__) return;
        // 搜索框下显示的历史记录
        void request.editor.addSpaceVisitsHistory(block.spaceId, { uuid });
      }
      if (!judgeSharePage()) {
        // 右边访问的可能是分享的页面id，如果不是同一个空间的就加到访问历史上
        if (isInRight && cache.blocks[masterPageId]?.spaceId !== block.spaceId) {
          return;
        }
        // 右上角头像的访问记录，share页面不记录
        void request.editor.addDocVisits.raw(uuid);
      }
    });
  }, [readonly, uuid, hasBlock, isLogin, isInRight, masterPageId, isTemplate]);

  useEffect(() => {
    if (!hasBlock) return;
    const block = getState().blocks[uuid];
    if (!block) return;
    bizTracker.event('page_enter', {
      is_success: true,
      page_type: block.type,
      page_id: block.uuid,
      is_shared: isSharePage,
    });
    if (isLogin) {
      void activeTracker.reportEnter({
        spaceId: block.spaceId,
        activeFrom: isSharePage ? 'share_link' : undefined,
      });
    }
  }, [uuid, hasBlock, isLogin, isSharePage]);

  // 同步到浏览器标题
  useEffect(() => {
    if (isInRight) return;
    if (hasBlock && !illegal) {
      let title = segmentsToText(block?.data.segments) || getUntitledName(block?.type);
      let desc = block?.data.seoDescription;

      if (isSharePage && block?.uuid) {
        if (block?.data.seoTitle) {
          title = block?.data.seoTitle;
        }
        if (!desc) {
          desc = getFirstTextByBlock(uuid);
        }
      }

      void setBrowserTitle({
        title,
        icon: {
          type: block?.data.icon?.type,
          value: block?.data.iconUrl || block?.data.icon?.value,
        },
        desc,
      });
    } else {
      void setBrowserTitle({ title: illegal ? ILLEGAL_TEXT : currentSpace.title });
    }
  }, [hasBlock, currentSpace.title, isInRight, illegal, block, isSharePage, uuid]);

  useEffect(() => {
    if (!block) return;
    const parent = getState().blocks[block.parentId];

    if (
      parent?.type === BlockType.COLLECTION_VIEW_PAGE ||
      parent?.type === BlockType.COLLECTION_VIEW
    ) {
      return sync.subscribeChildPages(block.spaceId, uuid, () => {
        void fetchPage(uuid);
      });
    }
  }, [block, fetchPage, sync, uuid]);

  useEffect(() => {
    const root = document.getElementById('root');
    if (isSharePage && !allowDuplicate) {
      root?.classList.add('no-print');
    } else {
      root?.classList.remove('no-print');
    }
  }, [allowDuplicate, isSharePage]);

  // 在这里监听loading结束，并将local改为false。
  // loading结束后，组件都已经创建完成，此时再将local改为false
  useEffect(() => {
    if (block?.local) {
      // 如果不加延迟，由于没有loading的缘故，组件没有卸载。而直接uuid触发effect
      // 导致先执行了这里更新local，然后外层才触发use effect; local就没用了
      setTimeout(() => {
        updateBlock(uuid, { local: false }, true);
      }, 200);
    }
  }, [block?.local, uuid]);

  useEffect(() => {
    const lastViewPage = localStorage.getItem(LAST_VIEW_PAGE);
    if (lastViewPage !== pageId && pageId && !isSharePage) {
      setLocalStorage(LAST_VIEW_PAGE, pageId);
    }
  }, [isSharePage, pageId]);

  if (illegal) {
    return <Empty.NoPermission uuid={uuid} />;
  }

  let content = null;
  switch (block?.type) {
    case BlockType.PAGE: {
      content = <PageDoc uuid={uuid}>{children}</PageDoc>;
      break;
    }

    case BlockType.COLLECTION_VIEW:
    case BlockType.REFERENCE_COLLECTION:
    case BlockType.REFERENCE_COLLECTION_PAGE:
    case BlockType.COLLECTION_VIEW_PAGE: {
      content = <PageBitable uuid={uuid}>{children}</PageBitable>;
      break;
    }
    case BlockType.FOLDER: {
      content = <PageDrive uuid={uuid}>{children}</PageDrive>;
      break;
    }
    case BlockType.MIND_MAPPING:
    case BlockType.MIND_MAPPING_PAGE: {
      content = <PageMind uuid={uuid} />;
      break;
    }
    case BlockType.CHART:
    case BlockType.CHART_PAGRE:
      content = <PageChart uuid={uuid} />;
      break;
    default:
      // 拿不到时，返回找不到页面
      if (uuid) {
        content = <Empty.NotFound showBackButton={false} />;
      } else {
        content = <PageDoc uuid={uuid}>{children}</PageDoc>;
      }
      break;
  }

  return (
    <JotaiProvider scope={PAGE_SCOPE} initialValues={[[$pageId, uuid]]}>
      <ReadonlyContext.Provider
        key={uuid}
        value={readonly2 || syncUpFault.backup || syncUpFault.noPermission}
      >
        {content}
      </ReadonlyContext.Provider>
    </JotaiProvider>
  );
};
