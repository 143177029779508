import i18next from 'i18next';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Language } from '../../hooks/use-language';

// 定义全局常量
export const GLOBAL_CONSTANTS = {
  PRODUCT_NAME: 'BuildIn AI',
  // 可以在这里添加更多全局常量
} as const;

// 扩展翻译函数的类型
type TFunction = (key: string | string[], options?: Record<string, any>) => string;

export const useChatTranslation = () => {
  const { t: originalT, ...rest } = useTranslation<Language>();

  // 包装 t 函数，确保返回类型为 string 并注入全局常量
  const t: TFunction = useCallback(
    (key, options = {}) => {
      return originalT(key, {
        ...GLOBAL_CONSTANTS,
        ...options,
      });
    },
    [originalT]
  );

  return useMemo(() => ({ t, ...rest }), [t, rest]);
};

export const changeChatLanguage = (language: Language) => {
  return i18next.changeLanguage(language);
};
