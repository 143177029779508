import { useCallback, useEffect } from 'react';
import type { AsyncReturnType } from 'type-fest';
import { API } from '../../api';
import { useOpenModal } from '../../next-modal';
import { emitter, EmitterEvent } from '../../utils/emitter';
import { createStore } from '../../zustand/utils';
import { useConversationContext } from './context';
import { useChatTranslation } from './use-i18n';

export const aiSpaceStatusStore = createStore<Record<string, string>>(() => ({}));

export const fetchAiSpaceStatus = async (spaceId: string) => {
  let status = await API.ai.checkQaWhiteList(spaceId);

  // 如果是 boolean false 是没开通，true 是开通了
  if (typeof status === 'boolean') {
    status = status ? 'ready' : 'none';
  }

  // 还有可能是 undefined 的情况，表示 false
  if (status === 'undefined') {
    status = 'none';
  }

  // 其他情况会显示排队数
  aiSpaceStatusStore.setState({ [spaceId]: status });
};

export const useAiSpaceStatus = (spaceId: string) => {
  return aiSpaceStatusStore((state) => state[spaceId] ?? 'none');
};

export const checkAiSpaceStatus = (spaceId: string) => {
  const status = aiSpaceStatusStore.getState()[spaceId];
  return status === 'ready';
};

const defaultLimit = {
  usage: 0,
  basic: 0,
  seat: 0,
  additional: 0,
  pdf: {
    usage: 0,
    basic: 0,
  },
  baseModel: {
    todayUsed: 0,
    monthUsed: 0,
    totalLimit: 0,
    dayLimit: 0,
    monthLimit: 0,
    leftReward: 0,
  },
  reachLimit: false,
};

interface AiLimit extends AsyncReturnType<typeof API.infra.getSpaceAiUsage> {}

export const aiLimitStore = createStore<Record<string, AiLimit>>(() => ({}));

export const fetchAiLimit = async (spaceId: string) => {
  if (!spaceId) return;
  const res = await API.infra.getSpaceAiUsage(spaceId);
  aiLimitStore.setState({ [spaceId]: res });
};

export const useAiLimit = (spaceId: string) => {
  return aiLimitStore((state) => state[spaceId] ?? defaultLimit);
};

export const checkLimit = (spaceId: string) => {
  const limit = aiLimitStore.getState()[spaceId] ?? defaultLimit;
  return !limit.reachLimit;
};

export const useCheckLimit = (spaceId: string) => {
  return aiLimitStore(() => checkLimit(spaceId));
};

export const useOpenLimitUpgrade = () => {
  const openModal = useOpenModal();
  const { t } = useChatTranslation();
  const { openUpgrade } = useConversationContext();

  return useCallback(() => {
    openModal.warning({
      title: t('ai_quota_exceeded_title'),
      content: t('ai_quota_exceeded_description'),
      confirmText: t('ai_quota_exceeded_upgrade_button'),
      confirm: openUpgrade,
      colorType: 'active',
      cancelText: t('ai_quota_exceeded_cancel'),
    });
  }, [openModal, openUpgrade, t]);
};

export const useListenerUpgradeAiLimit = () => {
  const openLimitUpgrade = useOpenLimitUpgrade();
  useEffect(() => {
    const listener = () => {
      openLimitUpgrade();
    };
    emitter.on(EmitterEvent.chatAiLimit, listener);
    return () => {
      emitter.off(EmitterEvent.chatAiLimit, listener);
    };
  }, [openLimitUpgrade]);
};
