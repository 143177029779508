import type { AIChatDTO, ConversationDetailDTO, QAQuestionDTO } from '@next-space/fe-api-idl';
import { API } from '../../api';
import { emitter, EmitterEvent } from '../../utils/emitter';
import { getScrollChatList } from '../const';
import { checkLimit, fetchAiLimit } from './ai-limit-store';
import { getEditor } from './cache';
import { getQAQueryOption } from './hook';
import { ReferenceTools, type ReferenceData } from './reference-tools';
import {
  addQuestion,
  conversationStore,
  getChatStatus,
  getConversationEdit,
  setAiChatPages,
  setChatStatus,
  setConversationEdit,
  updateChat,
  updateConversation,
  updateQuestion,
} from './store';
import { stopStream, streamAIResponse } from './stream';
import type { CreateChatParams } from './types';
import {
  AI_PAGE_ID,
  ChatStatus,
  defaultChat,
  defaultConversation,
  defaultConversationEditStore,
  defaultQuestion,
} from './types';

const containerScrollToBottom = (id: string) => {
  const container = document.getElementById(getScrollChatList(id));
  if (container) {
    requestAnimationFrame(() => {
      container.scrollTo({ top: container.scrollHeight });
    });
  }
};

export class ConversationTools extends ReferenceTools {
  constructor(id: string) {
    super(id, (id: string, data: Record<string, any>) => setConversationEdit(id, data));
  }

  getConversation = () => {
    const conversation = conversationStore.getState()[this.id];
    return conversation || defaultConversation;
  };

  getEditor = () => {
    return getEditor(this.id);
  };

  getConversationEdit = () => {
    return getConversationEdit(this.id);
  };

  protected getData = (): ReferenceData => {
    const edit = this.getConversationEdit();
    const qaQueryOption = getQAQueryOption(edit.qaQueryOption);
    return {
      qaQueryOption: {
        searchBlocks: qaQueryOption.searchBlocks,
        qaSearchSource: qaQueryOption.qaSearchSource,
        networkSearch: qaQueryOption.networkSearch,
      },
      status: getChatStatus(this.id),
    };
  };

  setStatus = (status: ChatStatus) => {
    setChatStatus(this.id, status);
  };

  getQuestions = () => {
    return this.getConversation().questions || [];
  };

  setKeywords = (keywords: string) => {
    setConversationEdit(this.id, { keywords });
  };

  /** 设置聊天列表 */
  setQuestions = (questions: QAQuestionDTO[]) => {
    updateConversation(this.id, { questions });
  };

  scrollToBottom = () => {
    containerScrollToBottom(this.id);
  };

  /** 清空聊天关键词和编辑器内容 */
  cleanConversationEditData = () => {
    setConversationEdit(this.id, { keywords: '' });

    this.setStatus(ChatStatus.DEFAULT);

    const editor = getEditor(this.id);

    if (editor) {
      editor.commands.setContent('');
      editor.commands.focus();
    }
  };

  /** 创建新的聊天会话 */
  createConversation = async (params: CreateChatParams) => {
    if (!checkLimit(params.spaceId)) {
      emitter.emit(EmitterEvent.chatAiLimit);
      throw new Error('ai limit exceeded');
    }

    if (this.id === AI_PAGE_ID) {
      this.cleanConversationEditData();
      this.setStatus(ChatStatus.LOADING);
    }

    const newConversation = await createNewConversation(params);
    this.cleanConversationEditData();

    // 异步创建消息
    const conversationTools = new ConversationTools(newConversation.id);
    conversationTools.cleanConversationEditData();
    void conversationTools.createQuestion(params);

    // 这里需要提前出去，完成路由跳转
    return newConversation.id;
  };

  createQuestion = async (
    params: CreateChatParams & {
      questionId?: string;
    }
  ) => {
    if (!checkLimit(params.spaceId)) {
      emitter.emit(EmitterEvent.chatAiLimit);
      throw new Error('ai limit exceeded');
    }

    if (this.getData().status !== ChatStatus.DEFAULT) {
      return;
    }

    this.cleanConversationEditData();
    this.setStatus(ChatStatus.CREATING);

    requestAnimationFrame(() => {
      this.scrollToBottom();
    });

    const { newQuestion, newChat } = await createNewQuestion({
      ...params,
      conversationId: this.id,
    });

    const chatTools = new ChatTools(this.id, newQuestion.id, newChat.uuid);
    void chatTools.startStream(params);

    return newQuestion;
  };

  stopAllChat = () => {
    this.setStatus(ChatStatus.DEFAULT);
    const questions = this.getQuestions();
    questions.forEach((question: QAQuestionDTO) => {
      question.chatList.forEach((chat: AIChatDTO) => {
        const chatTools = new ChatTools(this.id, question.id, chat.uuid);
        chatTools.stopChat();
      });
    });
  };
}

export class QuestionTools {
  protected id: string;
  protected conversationId: string;
  protected readonly conversationTools: ConversationTools;

  constructor(id: string, conversationId: string) {
    this.id = id;
    this.conversationId = conversationId;
    this.conversationTools = new ConversationTools(conversationId);
  }

  /** 获取当前聊天的状态 */
  getQuestion = () => {
    const conversation = this.conversationTools.getConversation();
    if (!conversation) return defaultQuestion;

    const question = conversation.questions.find((q) => q.id === this.id);
    return question || defaultQuestion;
  };
}

export class ChatTools extends ReferenceTools {
  protected questionId: string;
  protected readonly conversationTools: ConversationTools;
  protected readonly questionTools: QuestionTools;
  private readonly conversationId: string;

  constructor(conversationId: string, questionId: string, id: string) {
    super(id, (id: string, data: Record<string, any>) =>
      updateChat(conversationId, questionId, id, data)
    );
    this.conversationId = conversationId;
    this.questionId = questionId;
    this.conversationTools = new ConversationTools(conversationId);
    this.questionTools = new QuestionTools(questionId, conversationId);
  }

  public getData = (): ReferenceData => {
    const defaultQaQueryOption = defaultConversationEditStore.qaQueryOption;
    const defaultData = {
      ...defaultQaQueryOption,
      status: getChatStatus(this.id),
    } as ReferenceData;

    const state = conversationStore.getState();
    const conversation = state[this.conversationId];
    if (!conversation) {
      return defaultData;
    }

    const currentQuestion = conversation.questions.find((q) => q.id === this.questionId);
    if (!currentQuestion) {
      return defaultData;
    }

    const currentChat = currentQuestion.chatList.find((c) => c.uuid === this.id);
    if (!currentChat) {
      return defaultData;
    }

    const { qaQueryOption } = currentChat;

    return {
      qaQueryOption: getQAQueryOption(qaQueryOption),
      status: getChatStatus(this.id),
    };
  };

  /** 更新聊天内容 */
  updateChat = (data: Partial<AIChatDTO>) => {
    updateChat(this.conversationId, this.questionId, this.id, data);
  };

  /** 发送聊天消息 */
  startStream = async (params: CreateChatParams) => {
    this.conversationTools.setStatus(ChatStatus.SENDING);
    this.setStatus(ChatStatus.LOADING);

    requestAnimationFrame(() => {
      this.conversationTools.scrollToBottom();
    });

    let streamedResponse = '';
    void streamAIResponse(this.id, params.spaceId, (chunk) => {
      streamedResponse += chunk;
      this.updateChat({
        ai: streamedResponse,
      });
    }).finally(() => {
      void fetchAiLimit(params.spaceId);
      this.setStatus(ChatStatus.DEFAULT);
      this.conversationTools.setStatus(ChatStatus.DEFAULT);
    });
  };

  /** 停止消息流式响应 */
  stopChat = () => {
    stopStream(this.id);
    this.setStatus(ChatStatus.DEFAULT);
  };

  setStatus = (status: ChatStatus) => {
    setChatStatus(this.id, status);
  };
}

const createNewConversation = async (params: CreateChatParams) => {
  const res = (await API.ai.createConversation({
    spaceId: params.spaceId,
    question: params.keywords,
    parentId: params.parentId,
  })) as {
    id: string;
    createdAt: number;
    updatedAt: number;
    question: string;
  };

  const newConversation: ConversationDetailDTO = {
    id: res.id,
    question: res.question ?? '',
    questions: [],
    createdAt: res.createdAt,
    updatedAt: res.updatedAt,
    parentId: params.parentId,
    spaceId: params.spaceId,
  };

  setConversationEdit(newConversation.id, params);
  setChatStatus(newConversation.id, ChatStatus.LOADING);

  updateConversation(newConversation.id, newConversation);
  return newConversation;
};

const createNewQuestion = async (
  params: CreateChatParams & {
    conversationId: string;
    questionId?: string;
  }
) => {
  const res = await API.ai.postQaRetrieval({
    spaceId: params.spaceId,
    question: params.keywords,
    questionId: params.questionId,
    conversationId: params.conversationId,
    qaQueryOption: getQAQueryOption(params.qaQueryOption),
  });

  if (res.recordMap.blocks) {
    setAiChatPages(res.recordMap.blocks);
  }

  if (!res.id) {
    throw new Error('create new question failed');
  }

  let conversation = conversationStore.getState()[params.conversationId];
  const _question = conversation?.questions.find((q) => q.id === params.questionId);

  const newQuestion: QAQuestionDTO = _question ?? {
    id: res.id,
    question: params.keywords,
    conversationId: params.conversationId,
    chatList: [],
    createdAt: Date.now(),
  };

  if (conversation) {
    // 如果传入的 questionId 为空，则添加新的问题。
    // 如果存在，说明是重新回答，只需要增加 Chat
    if (!params.questionId) {
      addQuestion(params.conversationId, newQuestion);
    }
  }

  if (!res.chatId) {
    throw new Error('create new chat failed');
  }

  const newChat: AIChatDTO = {
    ...defaultChat,
    uuid: res.chatId,
    ai: '',
    user: params.keywords,
    createdAt: Date.now(),
    qaQueryOption: params.qaQueryOption,
    resultData: res.resultData,
  };

  conversation = conversationStore.getState()[params.conversationId];
  if (conversation) {
    const question = conversation.questions.find((q) => q.id === newQuestion.id);
    if (question) {
      question.chatList.push(newChat);
      updateQuestion(params.conversationId, newQuestion.id, { chatList: question.chatList });
    }
  }

  return {
    newQuestion,
    newChat,
  };
};
