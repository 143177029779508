import { Language, LanguageList, useLanguage } from '@flowus/common/hooks/use-language';
import { detectRegionIsRu } from '@flowus/common/utils/system';
import type { FC, MouseEvent } from 'react';
import { useEffect } from 'react';
import { isWindows } from 'react-device-detect';
import { Button } from 'src/common/components/button';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { Switch } from 'src/common/components/switch';
import { LOCAL_KEY_THEME_MODE } from 'src/common/const';
import { useCurrentUser } from 'src/hooks/user';
import { useFetchMe } from 'src/hooks/user/use-fetch-me';
import { clearBackupData, exportBackupData } from 'src/redux/middlewares/persistence';
import { setAppUiState, useThemeState } from 'src/services/app/hook';
import { setLocalStorage } from 'src/utils/local-storage';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';
import { SettingModalCommon, useOpenDropdownList } from '../common';
import { useUpdateUserSetting } from '../helper';
import { Option } from '../option';
import { useCloseSettingModal } from '../use-open-setting-modal';
import { ClipperSetting } from './clipper-setting';
import { OpenPageMode, openPageModeList, themeList } from './const';
import { NotificationSetting } from './notification-setting';
import { PageLayoutSetting } from './page-layout-setting';
import { PageWidthSetting } from './page-width-setting';
import { TimezoneSetting } from './timezone-setting';

export const GeneralSetting: FC<{}> = () => {
  const fetchMe = useFetchMe();
  const closeSettingModal = useCloseSettingModal();
  const openModal = useOpenModal();
  const theme = useThemeState();
  const currentUser = useCurrentUser();
  const updateUserSetting = useUpdateUserSetting();
  const selectTheme = themeList.find((i) => i.value === theme);
  // const beta = useLocalStateBeta();
  const openDropdownList = useOpenDropdownList();
  const { currentLanguage, changeLanguage } = useLanguage();

  const openMode = currentUser.setting?.openPageMode ?? OpenPageMode.JUMP;
  const selectedMode = openPageModeList.find((i) => i.value === openMode);

  const openSelectList = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      content: ({ onCloseModal }) => (
        <ListView
          className="next-modal w-36 py-2.5"
          items={themeList.map((i, index) => ({
            type: ListItemType.OPERATION,
            data: {
              title: i.title,
              value: i.value,
              selected: selectTheme?.value === i.value,
              tip: index === 0 ? undefined : `${ShortcutSystemSymbol}+Shift+L`,
            },
          }))}
          onItemClick={(item) => {
            setLocalStorage(LOCAL_KEY_THEME_MODE, item.data.value);
            setAppUiState({ $theme: item.data.value });
            onCloseModal();
          }}
        />
      ),
    });
  };

  const openSelectLanguage = async (event: MouseEvent) => {
    const isSystemIsRu = detectRegionIsRu.isRussian();

    const _list = LanguageList.filter((i) => {
      if (i.value === Language.ru) {
        if (!isSystemIsRu) {
          return false;
        }
      }
      return true;
    });

    openDropdownList({
      event,
      active: currentLanguage.value,
      list: _list,
      onClick: (item) => {
        changeLanguage(item.value);
        location.reload();
      },
    });
  };

  const openMethodMenu = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      content: ({ onCloseModal }) => (
        <ListView
          className="next-modal w-36 py-2.5"
          items={openPageModeList.map((i, index) => ({
            type: ListItemType.OPERATION,
            data: {
              title: i.title,
              value: i.value,
              selected: openMode === i.value,
              tip: index === 0 ? undefined : [isWindows ? 'Alt' : 'Option', '+点击'].join(''),
            },
          }))}
          onItemClick={(item) => {
            void updateUserSetting({ openPageMode: item.data.value });
            onCloseModal();
          }}
        />
      ),
    });
  };

  const exportLocalData = async () => {
    const id = 'exportLocalData';
    if (message.exists(id)) return;
    message.loading({ key: id, content: '正在导出数据' });
    await exportBackupData();
    await clearBackupData();
    message.success('已导出数据');
    message.closeMessage(id);
  };

  useEffect(() => {
    void fetchMe();
  }, [fetchMe]);

  return (
    <>
      <div className={SettingModalCommon.commonStyles.container}>
        <SettingModalCommon.Header title="通用设置" />

        <SettingModalCommon.Item
          leftChild="主题"
          rightChild={
            <>
              <SettingModalCommon.DropdownButton
                onClick={openSelectList}
                content={selectTheme?.title}
              />
            </>
          }
        />
        {__BUILD_IN__ && (
          <>
            <SettingModalCommon.Item
              leftChild="语言"
              rightChild={
                <>
                  <SettingModalCommon.DropdownButton
                    onClick={openSelectLanguage}
                    content={currentLanguage.title}
                  />
                </>
              }
            />
            <TimezoneSetting />
          </>
        )}
        <SettingModalCommon.SettingDivider className="my-2.5" />
        <NotificationSetting />

        {!(__BUILD_IN__ || __PRIVATE__) && (
          <>
            <SettingModalCommon.SettingDivider className="mb-2.5" />
            <ClipperSetting />
          </>
        )}

        <Option
          title="默认页面打开方式"
          description="单击页面默认会以指定的方式打开"
          rightPart={
            <SettingModalCommon.DropdownButton
              onClick={openMethodMenu}
              content={selectedMode?.title}
            />
          }
        />

        <Option
          title="使用右侧栏显示新建页面"
          description="开启后，新建页面会显示在右侧栏中"
          rightPart={
            <Switch
              open={!!currentUser.setting?.useRightPageByNew}
              onSwitch={(status) => {
                void updateUserSetting({ useRightPageByNew: status });
              }}
            />
          }
        />

        <PageWidthSetting />
        <Option
          title="新建页面目录"
          description="新建文档页面的目录开启状态"
          rightPart={
            <Switch
              open={!!currentUser.setting?.directoryMenu}
              onSwitch={(status) => {
                void updateUserSetting({ directoryMenu: status });
              }}
            />
          }
        />
        <PageLayoutSetting />
        <Option
          title={'内容预览浮窗'}
          description="对页面/文件及引用内容支持浮窗预览"
          rightPart={
            <Switch
              open={
                currentUser.setting?.previewDialog === undefined ||
                currentUser.setting?.previewDialog
              }
              onSwitch={async (open) => {
                void updateUserSetting({
                  previewDialog: open,
                });
              }}
            />
          }
        />

        <Option
          title={'高性能模式'}
          description="开启后，部分内容量大的页面将以高性能模式运行，但会无法使用一些功能，例如浏览器自带的搜索功能"
          rightPart={
            <Switch
              open={!!currentUser.setting?.enableHighPerformance}
              onSwitch={(status) => {
                void updateUserSetting({ enableHighPerformance: status });
              }}
            />
          }
        />
        <Option
          title={'导出本地错误日志'}
          description="出现错误警告时，导出数据信息以便与开发者协助排查问题"
          rightPart={<Button onClick={exportLocalData}>导出</Button>}
        />
        {/* {!__PRIVATE__ && (
          <Option
            title={'Beta 模式'}
            description="打开后，重启页面将会开启新版架构模式，适合解决卡顿或希望体验灰度功能的用户"
            rightPart={<Switch open={!!beta} onSwitch={(s) => $setLocalState({ beta: s })} />}
          />
        )} */}
      </div>

      <SettingModalCommon.FooterBar cancel={() => closeSettingModal()} cancelText="关闭" />
    </>
  );
};
