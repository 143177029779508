export const BuildInEmail = 'team@buildin.ai';
export const FlowUsEmail = 'cm@allflow.cn';

export enum ViewPath {
  login = '/login/',
  create = '/create/',
  preview = '/preview/',
  share = '/share/',
  form = '/form/',
  main = '/',
  notFoundPage = '/404',
  product = '/product',
  callback = '/callback',
  subscribe = '/subscribe/share/',
  notion = '/notion',
  pricing = '/pricing',
  template = '/template',
  download = '/download',
  blog = '/blog',
  chat = '/chat',
}
