import { formula } from '@flowus/formula';
import type { CollectionSchema, SegmentDTO } from '@next-space/fe-api-idl';
import { AggregationAction, CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import type { getRollupValue as getRollupValue0 } from 'src/bitable/table-view/cell/rollup/get-rollup-value';
import {
  isDateAggregation,
  isPercentAggregation,
  isShowOriginalValue,
} from 'src/bitable/table-view/footer/helper';
import { checkPropRecur } from 'src/bitable/v2';
import type { BiSchemaResolver } from 'src/bitable/v2/utils/resolvers';
import { DATE_TIME_FORMAT } from 'src/common/const';
import { segmentsToText } from 'src/editor/utils/editor';
import { buildDateSegment, readDateFromDateSegment } from 'src/editor/utils/segments';
import { getState as getAppState, getState } from 'src/redux/store';
import { formatFloat, percentToNumber } from 'src/utils/number';
import { getUserName } from '../user/use-remark-name';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import type { RootState } from 'src/redux/types';
import { arrayToStyle, generateFormatByDate } from '@flowus/common';
import { isBuildIn } from '@flowus/common/build-in';
import {
  FormulaPersonValue,
  FormulaSinglePersonValue,
} from 'src/bitable/table-view/cell/formula/v2/person';
import { FormulaValue } from 'src/bitable/table-view/cell/formula/v2/formula-value';
import { EvalValue } from 'src/bitable/table-view/cell/formula/eval-value';
import { cache } from 'src/redux/reducers';

export let _getRollupValue: typeof getRollupValue0;

export const antiCycleSet_getRollupValue_0 = (getRollupValue: typeof _getRollupValue) => {
  _getRollupValue = getRollupValue;
};

const getRollupValue: typeof _getRollupValue = (...args) => _getRollupValue(...args);

export interface FormulaError {
  type: 'error';
  pos: {
    pmin: number;
    pmax: number;
  };
  message: string;
}

export interface FormulaPreview {
  type: 'preview';
  preview: string | React.ReactNode;
}

export interface FormulaTool {
  fromServer(formula: formula.ServerFormula): string;
  toServer(formula: string): formula.ServerFormula;
  getAvailablePropIds(propId: string): Set<string>;
  getType(propId: string): formula.ValueType | null;
  getTypeAsCollectionSchemaType(propId: string): CollectionSchemaType;
  getBoxedValue(recordId: string, propId: string): formula.Value;
  getValue(
    recordId: string,
    propId: string
  ):
    | string
    | string[]
    | number
    | boolean
    | Date
    | formula.ListValue
    | formula.Select
    | formula.DateRangeValue
    | formula.User
    | null;
  getValueAsSegments(recordId: string, propId: string): SegmentDTO[] | undefined;
  render(recordId: string, propId: string): string;
  validate(
    formula: string,
    collectionId: string,
    recordId: string,
    propId: string
  ): FormulaError | FormulaPreview;
}

class MyEvalContext extends formula.EvalContext {
  constructor(private getState = getAppState) {
    super();
  }

  readProp(recordId: string, propId: string): formula.Value | null {
    const state = this.getState();
    const { blocks } = state;
    const record = blocks[recordId];
    if (record == null) return null;

    const collectionId = record.parentId;
    const collection = blocks[collectionId];
    if (collection == null) return null;

    const propSchema = collection.data.schema?.[propId];
    if (propSchema == null) return null;

    if (propSchema.type === CollectionSchemaType.FORMULA) {
      const formulaTool = getFormulaTool(collectionId);
      return formulaTool.getBoxedValue(recordId, propId);
    }

    if (propSchema.type === CollectionSchemaType.ROLLUP) {
      const {
        dateStartTimestamp,
        dateEndTimestamp,
        aggregationValue,
        originValues,
        originValueToStringFunc,
      } = getRollupValue(recordId, propId) ?? {};
      if (isShowOriginalValue(propSchema.aggregation)) {
        return formula.ValueTool.string(
          originValues?.map((it) => originValueToStringFunc?.(it)).join(', ') ?? ''
        );
      } else if (isDateAggregation(propSchema.aggregation)) {
        if (propSchema.aggregation === AggregationAction.DATE_RANGE) {
          return dateStartTimestamp == null
            ? null
            : formula.ValueTool.string(
                `${dayjs(dateStartTimestamp).format(DATE_TIME_FORMAT)} - ${dayjs(
                  dateEndTimestamp
                ).format(DATE_TIME_FORMAT)}`
              );
        }
        const date = dayjs(dateStartTimestamp);
        return dateStartTimestamp !== undefined && date.isValid()
          ? formula.ValueTool.date(date.toDate())
          : null;
      } else if (isPercentAggregation(propSchema.aggregation)) {
        if (typeof aggregationValue === 'string') {
          return formula.ValueTool.number(percentToNumber(aggregationValue));
        }
        return null;
      }
      return formula.ValueTool.number(Number(aggregationValue));
    }

    if (propSchema.type === CollectionSchemaType.CREATED_AT) {
      return record.createdAt == null ? null : formula.ValueTool.date(new Date(record.createdAt));
    }

    if (propSchema.type === CollectionSchemaType.UPDATED_AT) {
      return record.updatedAt == null ? null : formula.ValueTool.date(new Date(record.updatedAt));
    }

    if (propSchema.type === CollectionSchemaType.CREATED_BY) {
      const text = record.createdBy == null ? '' : getUserName(record.createdBy);
      return formula.ValueTool.string(text);
    }

    if (propSchema.type === CollectionSchemaType.UPDATED_BY) {
      const text = record.updatedBy == null ? '' : getUserName(record.updatedBy);
      return formula.ValueTool.string(text);
    }

    const segments =
      propSchema.type === CollectionSchemaType.TITLE
        ? record.data.segments
        : record.data.collectionProperties?.[propId];

    if (propSchema.type === CollectionSchemaType.CHECKBOX) {
      return formula.ValueTool.boolean(formatCheckBoxValue(segmentsToText(segments)));
    }

    if (propSchema.type === CollectionSchemaType.NUMBER) {
      const text = segmentsToText(segments);
      let num = parseFloat(text);
      if (Number.isNaN(num)) {
        num = parseFloat(text.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
      }

      if (Number.isNaN(num)) return formula.ValueTool.number(0);
      return formula.ValueTool.number(num);
    }

    if (propSchema.type === CollectionSchemaType.DATE) {
      if (segments == null) return null;
      const segment = segments.find(
        (it) => it.type === TextType.DATE || it.type === TextType.DATETIME
      );
      if (segment == null) return null;
      const date = readDateFromDateSegment(segment);
      const endDate = segment.endDate ? readDateFromDateSegment(segment, true) : null;
      if (!dayjs(date).isValid()) return null;
      if (endDate != null && dayjs(endDate).isValid()) {
        return formula.ValueTool.dateRange(date, endDate);
      }
      return formula.ValueTool.date(date);
    }

    if (propSchema.type === CollectionSchemaType.PERSON) {
      const elements = (segments ?? []).filter((it) => it.type === TextType.USER);
      // return formula.ValueTool.string(
      //   elements
      //     .map((v) => {
      //       const userName = getUserName(v.uuid);
      //       return userName;
      //     })
      //     .join(', ')
      // );
      const users = elements
        .filter((v) => {
          const user = state.users[v.uuid ?? ''];
          return !!user;
        })
        .map((v) => {
          const user = state.users[v.uuid ?? '']!;
          const userName = getUserName(user.uuid);
          return formula.ValueTool.elementUser(user.uuid, userName, user.email);
        });
      return formula.ValueTool.list(formula.ValueTool.userType, users);
    }

    if (propSchema.type === CollectionSchemaType.FILE) {
      // const files = (segments ?? [])
      //   .filter((it) => it.type === TextType.URL && it.fileStorageType === 'internal')
      //   .map((it) => formula.ValueTool.elementFile(it.text, it.url ?? ''));
      // return formula.ValueTool.list(formula.ValueTool.fileType, files);
      const fileNames = (segments ?? [])
        .filter((it) => it.type === TextType.URL && it.fileStorageType === 'internal')
        .map((v) => {
          return v.text;
        })
        .join(', ');
      return formula.ValueTool.string(fileNames);
    }

    if (propSchema.type === CollectionSchemaType.MULTI_SELECT) {
      // const text = segmentsToText(segments);
      // const selects = text.split(',');
      // const formulaSelects: formula.Select[] = [];
      // propSchema.options?.forEach((v) => {
      //   if (selects.includes(v.value)) {
      //     formulaSelects.push(formula.ValueTool.elementSelect(v.value, v.color));
      //   }
      // });
      // return formula.ValueTool.list(formula.ValueTool.selectType, formulaSelects);
      const text = segmentsToText(segments);
      const selects = text.split(',').join(', ');
      return formula.ValueTool.string(selects);
    }
    if (propSchema.type === CollectionSchemaType.SELECT) {
      let text = '';
      let color = '';
      if (segments && segments.length > 0) {
        text = segmentsToText([segments[0]!]);
      }
      const option = propSchema.options?.filter((v) => v.value === text)[0];
      if (option) {
        color = option.color;
      }
      return formula.ValueTool.string(text);
    }
    if (propSchema.type === CollectionSchemaType.RELATION) {
      return formula.ValueTool.string(segmentsToText(segments, 0, false, ', '));
    }

    return formula.ValueTool.string(segmentsToText(segments));
  }
}

const createFormulaTool = (
  collId: string,
  tableSchema0: Record<string, CollectionSchema>,
  resolver: BiSchemaResolver,
  evalCtx: MyEvalContext
) => {
  const tableSchema = tableSchema0 ?? {};

  const noTypeSchema = new formula.Schema();
  for (const [propId, propSchema] of Object.entries(tableSchema)) {
    noTypeSchema.defineProp(propId, propSchema.name, null);
  }

  const fromServer = (serverFormula: formula.ServerFormula) => {
    try {
      return formula.Formula.fromServer(serverFormula, noTypeSchema);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
      return '';
    }
  };

  const computeAvailablePropsForFormula = (formulaPropId: string) => {
    const result = new Set<string>();
    for (const [propId] of Object.entries(tableSchema)) {
      if (!checkPropRecur(collId, formulaPropId, collId, propId, resolver)) {
        result.add(propId);
      }
    }
    return result;
  };

  const formulaExprs = new Map<string, formula.Expr | null>();

  const buildSchema = (propIds: Set<string>) => {
    const schema = new formula.Schema();
    for (const propId of propIds) {
      const propSchema = tableSchema[propId];
      if (propSchema == null) continue;

      if (propSchema.type === CollectionSchemaType.FORMULA) {
        const expr = formulaExprs.get(propId);
        schema.defineProp(
          propId,
          propSchema.name,
          expr == null ? formula.ValueTool.stringType : formula.ExprTool.typeOf(expr)
        );
      } else if (propSchema.type === CollectionSchemaType.ROLLUP) {
        if (isShowOriginalValue(propSchema.aggregation)) {
          schema.defineProp(propId, propSchema.name, formula.ValueTool.stringType);
        } else if (isDateAggregation(propSchema.aggregation)) {
          if (propSchema.aggregation === AggregationAction.DATE_RANGE) {
            schema.defineProp(propId, propSchema.name, formula.ValueTool.stringType);
          } else {
            schema.defineProp(propId, propSchema.name, formula.ValueTool.dateType);
          }
        } else {
          schema.defineProp(propId, propSchema.name, formula.ValueTool.numberType);
        }
      } else if (propSchema.type === CollectionSchemaType.NUMBER) {
        schema.defineProp(propId, propSchema.name, formula.ValueTool.numberType);
      } else if (
        propSchema.type === CollectionSchemaType.DATE ||
        propSchema.type === CollectionSchemaType.CREATED_AT ||
        propSchema.type === CollectionSchemaType.UPDATED_AT
      ) {
        schema.defineProp(propId, propSchema.name, formula.ValueTool.dateType);
      } else if (propSchema.type === CollectionSchemaType.CHECKBOX) {
        schema.defineProp(propId, propSchema.name, formula.ValueTool.booleanType);
      } else if (propSchema.type === CollectionSchemaType.PERSON) {
        schema.defineProp(
          propId,
          propSchema.name,
          formula.ValueTool.listType(formula.ValueTool.userType)
        );
      }
      // // 由于很多地方用到了字符串对比，这里用具体对象的话，会导致以前的公式全部报错
      // else if (propSchema.type === CollectionSchemaType.SELECT) {
      //   schema.defineProp(propId, propSchema.name, formula.ValueTool.selectType);
      // } else if (propSchema.type === CollectionSchemaType.MULTI_SELECT) {
      //   schema.defineProp(
      //     propId,
      //     propSchema.name,
      //     formula.ValueTool.listType(formula.ValueTool.selectType)
      //   );
      // } else if (propSchema.type === CollectionSchemaType.FILE) {
      //   schema.defineProp(
      //     propId,
      //     propSchema.name,
      //     formula.ValueTool.listType(formula.ValueTool.fileType)
      //   );
      // }
      else {
        schema.defineProp(propId, propSchema.name, formula.ValueTool.stringType);
      }
    }
    return schema;
  };
  const processFormula = (propId: string) => {
    const propSchema = tableSchema[propId];
    if (
      propSchema == null ||
      propSchema.type !== CollectionSchemaType.FORMULA ||
      formulaExprs.has(propId)
    ) {
      return;
    }

    const fail = () => {
      formulaExprs.set(propId, null);
    };
    if (propSchema.formula == null) return fail();
    const serverFormula = propSchema.formula as any as formula.ServerFormula;

    const availablePropIds = computeAvailablePropsForFormula(propId);
    const refPropIds = Object.keys(serverFormula.refProps);
    if (!refPropIds.every((it) => availablePropIds.has(it))) return fail();

    // 优先处理依赖
    for (const propId of refPropIds) processFormula(propId);

    const schema = buildSchema(availablePropIds);
    const formulaText = fromServer(serverFormula);
    const result = formula.Formula.parse(formulaText, schema);

    if (formula.ResultTool.isError(result)) return fail();
    const expr = formula.ResultTool.getOk(result);
    formulaExprs.set(propId, expr);
  };

  for (const propId of Object.keys(tableSchema)) {
    processFormula(propId);
  }

  const getBoxedValue = (recordId: string, propId: string) => {
    if (!formulaExprs.has(propId)) {
      return evalCtx.readProp(recordId, propId);
    }
    const expr = formulaExprs.get(propId);
    if (expr == null) return null;
    return formula.Formula.eval(expr, evalCtx, recordId);
  };
  const getValue = (recordId: string, propId: string) => {
    const value = getBoxedValue(recordId, propId);
    if (value == null) return null;
    const type = formula.ValueTool.typeOf(value);
    if (type === formula.ValueTool.numberType) {
      return formula.ValueTool.asNumber(value);
    } else if (type === formula.ValueTool.stringType) {
      return formula.ValueTool.asString(value);
    } else if (type === formula.ValueTool.booleanType) {
      return formula.ValueTool.asBoolean(value);
    } else if (type === formula.ValueTool.dateType) {
      return formula.ValueTool.asDate(value);
    } else if (type === formula.ValueTool.richTextType) {
      return formula.ValueTool.asRichText(value);
    } else if (formula.ValueTool.isListType(type)) {
      return formula.ValueTool.asList(value);
    } else if (type === formula.ValueTool.dateRangeType) {
      return formula.ValueTool.asDateRange(value);
    } else if (type === formula.ValueTool.selectType) {
      return formula.ValueTool.asSelect(value);
    } else if (type === formula.ValueTool.fileType) {
      return formula.ValueTool.asFile(value);
    } else if (type === formula.ValueTool.userType) {
      return formula.ValueTool.asUser(value);
    }
    return null;
  };
  const getType = (propId: string): formula.ValueType | null => {
    const expr = formulaExprs.get(propId);
    return expr == null ? null : formula.ExprTool.typeOf(expr);
  };
  return {
    fromServer,
    toServer(formulaText: string) {
      return formula.Formula.toServer(formulaText, noTypeSchema);
    },
    getType,
    getTypeAsCollectionSchemaType(propId: string): CollectionSchemaType {
      const type = getType(propId);
      if (type === formula.ValueTool.numberType) return CollectionSchemaType.NUMBER;
      if (type === formula.ValueTool.booleanType) return CollectionSchemaType.CHECKBOX;
      if (
        type === formula.ValueTool.dateType ||
        (type === formula.ValueTool.dateType) === formula.ValueTool.dateRangeType
      ) {
        return CollectionSchemaType.DATE;
      }
      return CollectionSchemaType.TEXT;
    },
    getBoxedValue,
    getValue,
    getValueAsSegments(recordId: string, propId: string): SegmentDTO[] | undefined {
      const value = getBoxedValue(recordId, propId);
      if (value == null) return undefined;
      const type = formula.ValueTool.typeOf(value);
      if (type === formula.ValueTool.numberType) {
        return [
          {
            type: TextType.TEXT,
            text: `${formula.ValueTool.asNumber(value)}`,
            enhancer: {},
          },
        ];
      } else if (type === formula.ValueTool.stringType) {
        return [
          {
            type: TextType.TEXT,
            text: formula.ValueTool.asString(value),
            enhancer: {},
          },
        ];
      } else if (type === formula.ValueTool.booleanType) {
        return formula.ValueTool.asBoolean(value)
          ? [
              {
                type: TextType.TEXT,
                text: 'YES',
                enhancer: {},
              },
            ]
          : undefined;
      } else if (type === formula.ValueTool.dateType) {
        return [buildDateSegment({ from: formula.ValueTool.asDate(value) })];
      }
      return undefined;
    },
    getAvailablePropIds(propId: string) {
      return computeAvailablePropsForFormula(propId);
    },
    render(recordId: string, propId: string) {
      const expr = formulaExprs.get(propId);
      const value = expr == null ? null : formula.Formula.eval(expr, evalCtx, recordId);
      return formula.ValueTool.toString(value);
    },
    validate(
      text: string,
      collectionId: string,
      recordId: string,
      propId
    ): FormulaError | FormulaPreview {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const availablePropIds = computeAvailablePropsForFormula(propId);
      const schema = buildSchema(availablePropIds);
      const result = formula.Formula.parse(text, schema);
      if (formula.ResultTool.isError(result)) {
        const error = formula.ResultTool.getError(result);
        return {
          type: 'error',
          pos: formula.ErrorTool.getPosition(error),
          message: formula.ErrorTool.toString(error),
        };
      }
      const expr = formula.ResultTool.getOk(result);
      const value = formula.Formula.eval(expr, evalCtx, recordId);
      if (!value) {
        return {
          type: 'preview',
          preview: '',
        };
      }
      const type = formula.ValueTool.typeOf(value);
      let preview: string | React.ReactNode = '';
      if (type === formula.ValueTool.numberType) {
        preview = formatFloat(formula.ValueTool.asNumber(value));
      } else if (type === formula.ValueTool.richTextType) {
        preview = <FormulaValue value={value} collectionId={collectionId} propertyId={propId} />;
      } else if (type === formula.ValueTool.dateType) {
        const date = formula.ValueTool.asDate(value);
        const dateFormat = generateFormatByDate(date);
        preview = dayjs(date)
          .locale(isBuildIn() ? 'en' : 'zh-cn')
          .format(dateFormat);
      } else if (type === formula.ValueTool.userType) {
        preview = <FormulaValue value={value} collectionId={collectionId} propertyId={propId} />;
      } else if (formula.ValueTool.isListType(type)) {
        const list = formula.ValueTool.asList(value);
        const elementType = formula.ValueTool.getListElementType(type);
        if (elementType === formula.ValueTool.richTextType) {
          preview = <FormulaValue value={value} collectionId={collectionId} propertyId={propId} />;
        } else if (elementType === formula.ValueTool.userType) {
          preview = <FormulaValue value={value} collectionId={collectionId} propertyId={propId} />;
        } else if (elementType === formula.ValueTool.selectType) {
          preview = list.elements
            .map((v) => {
              return (v as formula.Select).name;
            })
            .join(',');
        } else if (elementType === formula.ValueTool.fileType) {
          preview = list.elements
            .map((v) => {
              return (v as formula.File).name;
            })
            .join(',');
        } else if (formula.ValueTool.isListType(elementType)) {
          preview = list.elements
            .map((v) => {
              return (v as formula.ListValue).elements.join(', ');
            })
            .join(', ');
        } else {
          // TString
          preview = list.elements
            .map((v) => {
              return v;
            })
            .join(',');
        }
      } else {
        preview = formula.ValueTool.toString(value);
      }
      return {
        type: 'preview',
        preview,
      };
    },
  } as FormulaTool;
};

// 使用 Symbol 来避免缓存巨大无比的 state
const FORMULA_TOOLS_OF_STATE = Symbol('Formula Tools');

const getFormulaToolNoMemo = (collId: string, state: RootState) => {
  const schema = state.blocks[collId]?.data.schema ?? {};
  const evalCtx = new MyEvalContext(() => state);
  const resolver: BiSchemaResolver = {
    findSchema(collId, propId) {
      return state.blocks[collId]?.data.schema?.[propId];
    },
  };
  return createFormulaTool(collId, schema, resolver, evalCtx);
};

export const getFormulaTool = (collId: string, state = getAppState()) => {
  let formulaTools = (state as any)[FORMULA_TOOLS_OF_STATE] as Map<string, FormulaTool> | undefined;
  if (formulaTools == null) {
    formulaTools = new Map();
    (state as any)[FORMULA_TOOLS_OF_STATE] = formulaTools;
  }
  let formulaTool = formulaTools.get(collId);
  if (formulaTool == null) {
    formulaTool = getFormulaToolNoMemo(collId, state);
    formulaTools.set(collId, formulaTool);
  }
  return formulaTool;
};
