import { cx } from '@flowus/common/cx';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { useBitable } from 'src/bitable/context';
import { segmentsToText } from 'src/editor/utils/editor';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { Site, type CellViewProps } from './types';
import { TextValueView } from './text';
import type { formula } from '@flowus/formula';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { PersonTag } from './person';
import { css } from 'otion';
import { LOCAL_LNG } from '@flowus/common/const';
import { TagItem } from './select/tag-item';
import { COLORS } from 'src/bitable/const';
import { FileRegex } from '@flowus/common/regex';
import { arrayToStyle, canPreViewImage, getFileNameInfo } from '@flowus/common';
import { FilePreViewIcon } from 'src/components/file-preview-icon';

interface Props {
  children: ReactNode;
  noWrap?: boolean;
  className?: string;
}

export const ArrayValueView: FC<Props> = ({ children, className, noWrap, ...rest }) => {
  return (
    <div {...rest} className={cx(className, noWrap ? 'whitespace-nowrap' : 'break-words')}>
      {children}
    </div>
  );
};

export const ArrayValue: FC<CellViewProps> = ({ className, recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const text = useObservableStore(
    (state) => {
      const { blocks } = state;
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (schema?.type === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(collectionId, state);
        const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
        if (!ret) return '';
        return ret.elements.map((v) => v.toString()).join(', ');
      }
      const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
      return segmentsToText(segments);
    },
    [recordId, propertyId, collectionId]
  );

  if (!text) return null;

  return <TextValueView className={cx('p-2 leading-5', className)}>{text}</TextValueView>;
};
export const ArrayListValue: FC<CellViewProps> = ({ className, recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const text = useObservableStore(
    (state) => {
      const { blocks } = state;
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (schema?.type === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(collectionId, state);
        const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
        if (!ret) return '';
        return ret.elements
          .map((v) => {
            return (v as formula.ListValue).elements.join(', ');
          })
          .join(', ');
      }
      const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
      return segmentsToText(segments);
    },
    [recordId, propertyId, collectionId]
  );

  if (!text) return null;

  return <TextValueView className={cx('p-2 leading-5', className)}>{text}</TextValueView>;
};

export const ArrayOfUserValue: FC<CellViewProps> = ({ className, recordId, propertyId, site }) => {
  const { collectionId } = useBitable();
  const userIds = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
      if (!ret) return [];
      if (!ret.elements?.length) return [];
      return ret.elements.map((v) => {
        return (v as formula.User).uuid;
      });
    },
    [recordId, propertyId, collectionId]
  );

  return (
    <div
      className={cx(
        'flex',
        site === Site.LIST || site === Site.CALENDAR ? '' : 'flex-wrap',
        site === Site.FIELD &&
          css({
            selectors: {
              ':empty:before': {
                content: `'${LOCAL_LNG.isEmpty}'`,
                display: 'block',
                fontSize: '14px',
                padding: '8px',
                color: 'var(--grey4)',
                lineHeight: '20px',
              },
            },
          }),
        className
      )}
    >
      {userIds.map((v) => {
        return <PersonTag key={v} userId={v ?? ''} site={site} />;
      })}
    </div>
  );
};
export const UserValue: FC<CellViewProps> = ({ className, recordId, propertyId, site }) => {
  const { collectionId } = useBitable();
  const userIds = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const user = formulaTool.getValue(recordId, propertyId) as formula.User;
      if (!user) return [];
      return [user.uuid];
    },
    [recordId, propertyId, collectionId]
  );

  return (
    <div
      className={cx(
        'flex',
        site === Site.LIST || site === Site.CALENDAR ? '' : 'flex-wrap',
        site === Site.FIELD &&
          css({
            selectors: {
              ':empty:before': {
                content: `'${LOCAL_LNG.isEmpty}'`,
                display: 'block',
                fontSize: '14px',
                padding: '8px',
                color: 'var(--grey4)',
                lineHeight: '20px',
              },
            },
          }),
        className
      )}
    >
      {userIds.map((v) => {
        return <PersonTag key={v} userId={v ?? ''} site={site} />;
      })}
    </div>
  );
};
export const ArrayOfRichText: FC<CellViewProps> = ({ recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const segments = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
      if (!ret) return [];
      if (!ret.elements?.length) return [];
      return ret.elements.map((v) => {
        return arrayToStyle(v as string[])[0]!;
      });
    },
    [recordId, propertyId, collectionId]
  );
  return (
    <div className="p-2 w-full group-scope">
      <RichText className={cx('leading-[20px]')} segments={segments} interactable={true} />
      {/* <CopyButton text={text} site={site} /> */}
    </div>
  );
};
export const ArrayOfSelectValue: FC<CellViewProps> = ({ recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const selects = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
      if (ret === null) return [];
      return ret.elements.map((v) => v as formula.Select);
    },
    [recordId, propertyId, collectionId]
  );

  if (selects.length === 0) return null;

  return (
    <div className="relative p-[5px] w-full">
      {selects.map((select, index) => {
        return (
          <TagItem
            className="flex-shrink-0 max-w-[calc(100%-6px)] m-[3px]"
            key={index}
            color={select.color}
            value={select.name}
            label={select.name}
          />
        );
      })}
    </div>
  );
};
export const ArrayOfFile: FC<CellViewProps> = ({ recordId, propertyId, site }) => {
  const { collectionId } = useBitable();
  const files = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
      if (!ret) return [];
      if (!ret.elements?.length) return [];
      return ret.elements.map((v) => {
        return v as formula.File;
      });
    },
    [recordId, propertyId, collectionId]
  );
  return (
    <div
      className={cx(
        'flex items-center',
        site === Site.FIELD &&
          css({
            selectors: {
              ':empty:before': {
                content: `'${LOCAL_LNG.isEmpty}'`,
                display: 'block',
                fontSize: '14px',
                padding: '3px',
                color: 'var(--grey4)',
                lineHeight: '20px',
              },
            },
          }),
        (site === Site.CELL || site === Site.FIELD) && 'p-[5px]',
        (site === Site.CELL || site === Site.FIELD || site === Site.CARD) && 'flex-wrap'
      )}
    >
      {files.map((it, index) => {
        const canPreView = canPreViewImage(it.ossName);
        const tagItem = (
          <TagItem
            key={index}
            label={it.name}
            color={COLORS[0].key}
            className={cx(
              'align-middle max-w-[calc(100%-6px)]',
              (site === Site.CELL || site === Site.FIELD) && 'm-[3px]',
              (site === Site.CARD || site === Site.LIST) && 'mr-1 my-0.5 text-t4',
              site === Site.CALENDAR && 'mr-1 my-[1px] text-t4'
            )}
          />
        );

        if (canPreView) {
          const previewImage = FileRegex.image.test(getFileNameInfo(it.name).extName);
          return (
            <FilePreViewIcon
              key={index}
              className={cx(
                'h-5 w-5 m-[3px] cursor-pointer border border-grey6 rounded-sm overflow-hidden inline-flex',
                (site === Site.CELL || site === Site.FIELD) && 'm-[3px]',
                site === Site.CARD || site === Site.LIST ? 'mr-1 my-0.5' : 'm-[3px]',
                site === Site.CALENDAR && 'mr-1 my-[1px]'
              )}
              uuid={recordId}
              ossName={it.ossName}
              defaultIcon={tagItem}
              options={{
                videoPlayIconSize: 'small',
                resizeWidth: 40,
                previewImage,
              }}
            />
          );
        }
        return tagItem;
      })}
    </div>
  );
};
