import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType, PermissionType, TextType } from '@next-space/fe-api-idl';
import { assign, last } from 'lodash-es';
import type { IconName } from 'src/common/components/icon';
import type { SearchItem } from 'src/components/search';
import { covertNextBlockToSearchItem } from 'src/components/search/covert-next-block-to-search-item';
import { listFormat, orderListFormat } from 'src/editor/component/menu-list/const';
import { LAST_SELECTED_CODE_LANGUAGE } from 'src/editor/editor/plugin/code/const';
import { segmentsToText } from 'src/editor/utils/editor';
import { getAncestors } from 'src/hooks/block/use-get-ancestors';
import { descendantsSelector } from 'src/hooks/block/use-get-descendants';
import { ID_HOVER_MENU } from 'src/hooks/page/use-dnd/helper';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch, getState } from 'src/redux/store';
import type { NextBlock, RootState, SelectBlock } from 'src/redux/types';
import { SpacePlanType } from 'src/redux/types';
import { validate } from 'uuid';
import { isEditableType, isLinkPageBlock, isPageLike, supportCaption } from './block-type-utils';
import { querySelectorAllFromMainContent } from './dom';
import { getFileIcon, getSegmentsFileNameInfo } from './file';
import { ExecuteCmdUtil } from 'src/common/utils/execute-cmd-util';

export const getBlockIcon = (
  block?: Pick<NextBlock, 'type'> & { data: Pick<NextBlock['data'], 'segments'> }
) => {
  if (!block) return '';
  const { type, data } = block;
  const iconName = getBlockIconByType(type);
  if (iconName) return iconName;
  const { extName } = getSegmentsFileNameInfo(data.segments);
  return getFileIcon(extName);
};

export const supportWordWrap = (block?: Pick<NextBlock, 'data' | 'type'>) => {
  if (!block) return false;
  if (block.type === BlockType.CODE) {
    return true;
  }
  return false;
};

export const supportComments = (block?: Pick<NextBlock, 'type'>) => {
  if (!block) return false;
  switch (block.type) {
    case BlockType.DIVIDER:
      return false;
    case BlockType.REFERENCE:
      return false;
    case BlockType.PAGE:
      return false;
    case BlockType.SYNC_REFERENCE:
      return false;
    case BlockType.SYNC_CONTAINER:
      return false;
    case BlockType.FOLDER:
      return false;
    default:
      return true;
  }
};

export const getBlockIconByType = (
  type: BlockType | string,
  empty?: boolean,
  isDriveIcon = false
): IconName => {
  const icons: Record<number, IconName> = {
    [BlockType.PAGE]: empty ? 'IcPageEmpty' : 'IcPages',
    [BlockType.FOLDER]: empty ? 'IcFolder' : 'IcFolderContent',
    [BlockType.COLLECTION_VIEW_PAGE]: 'IcTable',
    [BlockType.COLLECTION_VIEW]: 'IcTable',
    [BlockType.REFERENCE_COLLECTION]: 'IcTable',
    [BlockType.REFERENCE_COLLECTION_PAGE]: 'IcTable',
    [BlockType.MIND_MAPPING]: 'IcMindmap',
    [BlockType.MIND_MAPPING_PAGE]: 'IcMindmap',
    [BlockType.TEXTAREA]: 'MIcInsertBlockText',
    [BlockType.FILE]: 'IcBlockFile',
  };

  if (isDriveIcon) {
    assign(icons, {
      [BlockType.PAGE]: empty ? 'MIcPageMaxColor' : 'MIcPagesMaxColor',
      [BlockType.FOLDER]: empty ? 'MIcFileEmptyMaxColor' : 'MIcFileFillMaxColor',
      [BlockType.COLLECTION_VIEW_PAGE]: 'MIcTableMaxColor',
      [BlockType.COLLECTION_VIEW]: 'MIcTableMaxColor',
      [BlockType.REFERENCE_COLLECTION]: 'MIcTableMaxColor',
      [BlockType.REFERENCE_COLLECTION_PAGE]: 'MIcTableMaxColor',
      [BlockType.MIND_MAPPING]: 'MIcMindmapMaxColor',
      [BlockType.MIND_MAPPING_PAGE]: 'MIcMindmapMaxColor',
    });
  }

  // @ts-ignore type
  const _icon = icons[type];
  if (_icon) {
    return _icon;
  }

  return icons[BlockType.PAGE] as IconName;
};

export const getRefBlock = (uuid: string, blocks = cache.blocks) => {
  const block = blocks[uuid];
  if (isLinkPageBlock(block)) {
    if (block) {
      const refId = block.data.ref?.uuid;
      if (refId) {
        const refBlock = cache.blocks[refId];
        if (refBlock) {
          return refBlock;
        }
      }
    }
  }
};

/**
 *
 * 给[移动到]等需要搜索的列表用的，提供默认列表
 * @params ancestorId 会从ancestorId下寻找对应的类型
 * @params findTypes 不填的话就会找所有类型
 * @params filterTypes 需要过滤掉的类型
 * @params filterIds 需要过滤掉的id
 */
interface Params {
  ancestorId: string;
  findTypes?: BlockType[];
  filterTypes?: BlockType[];
  filterIds?: string[];
}
export const getDefaultSearchList = (params: Params) => {
  const { ancestorId, findTypes, filterTypes, filterIds } = params;
  const { blocks } = getState();
  const defaultItems: SearchItem[] = [];
  let items = blocks[ancestorId]?.subNodes;
  if (filterIds && items) {
    items = items.filter((id) => {
      return filterIds.indexOf(id) === -1;
    });
  }
  if (findTypes && items) {
    items = items.filter((id) => {
      const block = blocks[id];
      return block && findTypes.includes(block.type);
    });
  }
  if (filterTypes && items) {
    items = items.filter((id) => {
      const block = blocks[id];
      return block && !filterTypes.includes(block.type);
    });
  }

  items?.forEach((id) => {
    const item = covertNextBlockToSearchItem(blocks[id]);
    if (item) defaultItems.push(item);
  });
  return defaultItems;
};

export const getViewId = (block: NextBlock) => {
  return (
    // localStorage.getItem(`inline-bitable:${block.uuid}`)?.substring(1, 37) ||
    localStorage.getItem(`viewId:${block.uuid}`) || block.views?.[0]
  );
};

export const getRightLeaf = (blocks: RootState['blocks'], id: string) => {
  const result = new Set<string>();
  let current = blocks[id];

  while (current) {
    const lastId = last(current.subNodes);
    if (lastId) {
      result.add(lastId);
      current = blocks[lastId];
    } else {
      current = undefined;
    }
  }

  return result;
};

export const getSpacePlanTypeName = (planType?: SpacePlanType | string) => {
  if (planType === SpacePlanType.pro) return '专业版';
  if (planType === SpacePlanType.freePersonal) return '免费版';
  if (planType === SpacePlanType.freeTeam) return '免费版';
  if (planType === SpacePlanType.smallTeam) return '团队版';
  if (planType === SpacePlanType.team) return '团队版';
};

export const getPreBlock = (blockId: string) => {
  const parentId = cache.blocks[blockId]?.parentId;
  if (!parentId) return;
  const parentBlock = cache.blocks[parentId];
  if (!parentBlock) return;
  let preId;
  for (const id of parentBlock.subNodes) {
    if (id === blockId) break;
    preId = id;
  }
  if (!preId) return;
  return cache.blocks[preId];
};

export const getNextBlock = (blockId: string) => {
  const parentId = cache.blocks[blockId]?.parentId;
  if (!parentId) return;
  const parentBlock = cache.blocks[parentId];
  if (!parentBlock) return;
  let preId, nextId;
  for (const id of parentBlock.subNodes) {
    if (preId === blockId) {
      nextId = id;
      break;
    }
    preId = id;
  }
  if (!nextId) return;
  return cache.blocks[nextId];
};

/** 过滤掉所有已经含有的blockId的子孙id，多选block的时候如果有组或者内嵌多维表时，选中了整个组或者内嵌多维表，这个时候里面的block是不能 显示被选中背景的，要不然会叠加 */
export const filterDescendantId = (selectedBlocks: SelectBlock[]) => {
  return selectedBlocks.filter((v) => {
    // 如果不存在祖先id才保留
    const exist = isAlreadyExistAncestors(selectedBlocks, v);
    return !exist;
  });
};
export const isAlreadyExistAncestors = (selectedBlocks: SelectBlock[], block: SelectBlock) => {
  /** 把组和普通的多维表过滤掉 */
  let parentBlock = cache.blocks[cache.blocks[block.blockId]?.parentId ?? ''];
  while (parentBlock && parentBlock.spaceId !== parentBlock.parentId) {
    if (selectedBlocks.findIndex((v) => v.blockId === parentBlock?.uuid) > -1) return true;
    // if (allIds.has(parentBlock.uuid)) return true;
    parentBlock = cache.blocks[parentBlock.parentId];
  }
  /** 对引用多维表对情况下进行处理 */
  for (const selectBlock of selectedBlocks) {
    let _block = cache.blocks[selectBlock.blockId];
    if (_block?.type === BlockType.REFERENCE_COLLECTION) {
      const viewId = getViewId(_block);
      _block = cache.blocks[_block.data.ref?.uuid ?? ''];
      if (!_block) continue;
      const descendants = descendantsSelector(cache, _block.uuid, []);
      if (descendants.has(block.blockId) && viewId === block.viewId) return true;
    }
  }

  return false;
};

/** 从dom节点获取所有的blockId */
export const getAllBlockInfoFromDomContent = (isInRight: boolean) => {
  const blockIds: SelectBlock[] = [];
  const listNodes = querySelectorAllFromMainContent(
    `.block-content [data-block-id]:not(#${ID_HOVER_MENU})`,
    isInRight
  );
  const { blocks } = cache;
  for (const node of listNodes) {
    const { blockId, viewId, syncId } = (node as HTMLElement).dataset;
    if (!blockId) return;

    const block = blocks[blockId];
    if (!block) continue;

    const parent = blocks[block.parentId];
    if (!parent) continue;
    blockIds.push({ blockId, viewId, syncId });
  }
  return blockIds;
};
/** 通过dom拿到所有可编辑的点 */
export const getAllEditorInfoFromDomContent = (isInRight: boolean) => {
  const blockIds: SelectBlock[] = [];
  const editNodes = querySelectorAllFromMainContent(
    `.block-content [data-block-id] [data-editor]`,
    isInRight
  );
  const { blocks } = cache;
  for (const editorNode of editNodes) {
    const element = editorNode.closest('[data-block-id]');
    if (!element) continue;

    const { editor: editorKey } = (editorNode as HTMLElement).dataset;
    const { blockId, viewId, syncId } = (element as HTMLElement).dataset;
    if (!blockId) {
      // eslint-disable-next-line no-console
      __HOST_LOCAL__ && console.warn('blockId: not found', element);
      continue;
    }
    if (isPageLike(cache.blocks[blockId]?.type)) {
      continue;
    }

    const block = blocks[blockId];
    if (!block) continue;

    const parent = blocks[block.parentId];
    if (!parent) continue;
    blockIds.push({ blockId, viewId, syncId, editorKey });
  }
  return blockIds;
};

const getNextListFormat = (type: BlockType.ORDER_LIST | BlockType.LIST, value: string) => {
  let allValue: string[];
  if (type === BlockType.ORDER_LIST) {
    allValue = orderListFormat.map((format) => format.value);
  } else {
    allValue = listFormat.map((format) => format.value);
  }
  const index = (allValue.indexOf(value) + 1) % allValue.length;
  return allValue[index] ?? 'numbers';
};

// 兄弟节点上，从自己开始向前找对应的block，前一个是type的话就一直找，最终会找到连续为type的blocks的第一个block
export const lookBackwardSiblingBlock = (
  uuid: string,
  blocks: Record<string, NextBlock>,
  type: BlockType
) => {
  const block = blocks[uuid];
  if (!block) return;
  const parentBlock = blocks[block.parentId];
  if (!parentBlock) return;
  let index = parentBlock.subNodes.indexOf(uuid);
  if (index <= 0) {
    return { firstBlock: block, firstBlockIndex: 0 };
  }
  let firstBlock: NextBlock | undefined;
  while (index > 0) {
    index--;
    const preSiblingBlockId = parentBlock.subNodes[index];
    if (!preSiblingBlockId) {
      break;
    }
    const preSiblingBlock = blocks[preSiblingBlockId];
    if (!preSiblingBlock) {
      continue;
    }
    if (preSiblingBlock.type !== type) {
      break;
    }
    firstBlock = preSiblingBlock;
  }
  if (firstBlock) {
    return { firstBlock, firstBlockIndex: parentBlock.subNodes.indexOf(firstBlock.uuid) };
  }
};
// 往前找第一个有format的兄弟block
export const lookBackwardSiblingBlockWithHasFormat = (
  uuid: string,
  blocks: Record<string, NextBlock>,
  type: BlockType.LIST | BlockType.ORDER_LIST
) => {
  const block = blocks[uuid];
  if (!block) return;
  const parentBlock = blocks[block.parentId];
  if (!parentBlock) return;
  let index = parentBlock.subNodes.indexOf(uuid);
  if (index <= 0) {
    return { firstBlock: block, firstBlockIndex: 0 };
  }
  let firstBlock: NextBlock | undefined;
  while (index > 0) {
    index--;
    const preSiblingBlockId = parentBlock.subNodes[index];
    if (!preSiblingBlockId) {
      break;
    }
    const preSiblingBlock = blocks[preSiblingBlockId];
    if (!preSiblingBlock) {
      continue;
    }
    if (preSiblingBlock.type !== type) {
      break;
    }
    if (type === BlockType.ORDER_LIST && preSiblingBlock.data.format?.orderListFormat) {
      firstBlock = preSiblingBlock;
      break;
    } else if (type === BlockType.LIST && preSiblingBlock.data.format?.listFormat) {
      firstBlock = preSiblingBlock;
      break;
    }
  }
  if (firstBlock) {
    return { firstBlock, firstBlockIndex: parentBlock.subNodes.indexOf(firstBlock.uuid) };
  }
};

export const calculateListFormat = (
  uuid: string,
  blocks: Record<string, NextBlock>,
  type: BlockType.ORDER_LIST | BlockType.LIST
): { format: string | undefined; listNo: number; defaultFormat: string } | undefined => {
  const block = blocks[uuid];
  if (!block) return;
  let listNo = block.data.format?.listStartIndex || 1; // ios默认不处理会传0，所以这里要特意用||处理一下
  const blockFormat =
    type === BlockType.ORDER_LIST
      ? block.data.format?.orderListFormat
      : block.data.format?.listFormat;

  const defaultFormat = type === BlockType.ORDER_LIST ? 'numbers' : 'disc';
  const parentBlock = blocks[block.parentId];
  if (!parentBlock) return;
  // 先找兄弟节点算format和序号
  const firstBlockInfo = lookBackwardSiblingBlock(uuid, blocks, type);
  const firstHasFormatBlock = lookBackwardSiblingBlockWithHasFormat(uuid, blocks, type);

  // 算出序列号是多少
  if (firstBlockInfo) {
    const { firstBlock, firstBlockIndex } = firstBlockInfo;
    const index = parentBlock.subNodes.indexOf(uuid);
    listNo = index - firstBlockIndex + 1;

    if (firstBlock.data.format?.listStartIndex) {
      // 第一个序号block的序号可能是非1,需要加上偏移量listStartIndex-1
      listNo = listNo + firstBlock.data.format.listStartIndex - 1;
    }
  }
  // 如果自己本来就有format，就直接返回
  if (blockFormat) {
    return { format: blockFormat, listNo, defaultFormat };
  }
  // 如果兄弟节点以及有format，就直接用兄弟节点的format
  let format;
  if (type === BlockType.ORDER_LIST) {
    format = firstHasFormatBlock?.firstBlock.data.format?.orderListFormat;
  } else {
    format = firstHasFormatBlock?.firstBlock.data.format?.listFormat;
  }
  if (format) {
    return { format, listNo, defaultFormat };
  }
  // 如果兄弟节点都没有，就往上层找吧
  if (parentBlock.type === type) {
    // 如果父节点是相同类型，就继续算
    const formatInfo = calculateListFormat(block.parentId, blocks, type);
    if (formatInfo) {
      if (formatInfo.format) {
        return { format: formatInfo.format, listNo, defaultFormat };
      }
      return {
        format: blockFormat,
        listNo,
        defaultFormat: getNextListFormat(type, formatInfo.defaultFormat),
      };
    }
  }
  return { format: blockFormat, listNo, defaultFormat };
};

export const findAncestorBlock = (uuid: string, types: BlockType[], blocks = getState().blocks) => {
  let current = blocks[uuid];
  if (!current) return;
  current = blocks[current.parentId];
  let ancestorBlock: NextBlock | undefined = undefined;
  while (current) {
    if (types.includes(current.type)) {
      ancestorBlock = current;
      break;
    }
    current = blocks[current.parentId];
  }
  return ancestorBlock;
};
export const isInBlock = (uuid: string, types: BlockType[], blocks = getState().blocks) => {
  const ancestorBlock = findAncestorBlock(uuid, types, blocks);
  return Boolean(ancestorBlock);
};

export const getPreSiblingBlockId = (uuid: string, blocks = getState().blocks) => {
  const block = blocks[uuid];
  if (!block) return;
  const parentBlock = blocks[block.parentId];
  if (!parentBlock) return;
  const index = parentBlock.subNodes.indexOf(uuid);
  if (index <= 0) return;
  return parentBlock.subNodes[index - 1];
};
export const getNextSiblingBlockId = (uuid: string, blocks = getState().blocks) => {
  const block = blocks[uuid];
  if (!block) return;
  const parentBlock = blocks[block.parentId];
  if (!parentBlock) return;
  const index = parentBlock.subNodes.indexOf(uuid);
  return parentBlock.subNodes[index + 1];
};

/**
 * 兼容问题，无法直接复制text/next-space-blocks type的数据到剪切板
 * 只能调用execCommand来触发全局的复制监听，拿到ClipboardEvent才能塞自己格式的数据
 * https://stackoverflow.com/questions/61604528/in-browser-javascript-i-get-error-message-when-writing-csv-text-to-clipboard */
export const copyBlockToClipBoard = (id: string) => {
  dispatch(
    uiActions.update({
      selectedBlocks: [{ blockId: id }],
    })
  );
  ExecuteCmdUtil.execCommand('copy');
};

/** 查看选中块里面是否有同步块并且有一个括以上的同步页面的,有信息返回就要弹窗，如果是点击影子块的菜单unsync也需要弹出*/
export const getSyncBlockBackLinkInfo = (selectedBlocks: SelectBlock[], force?: boolean) => {
  // 如果有源同步块但是并没有同步过的话，可以直接删除
  const syncCountArray: number[] = [];
  selectedBlocks.forEach((v) => {
    if (cache.blocks[v.blockId]?.type === BlockType.SYNC_CONTAINER || force) {
      const node = document.querySelector(`[data-block-id="${v.blockId}"]`);
      if (node instanceof HTMLElement && node.dataset.syncCount) {
        syncCountArray.push(parseFloat(node.dataset.syncCount));
      }
    }
  });
  if (syncCountArray.length === 0) return;
  // 如果大于0说明有同步块已经正在同步的影子块，这两种情况都要弹窗
  const showDialog = syncCountArray.some((v) => {
    return v > 0;
  });
  if (!showDialog) return;
  const syncCount = syncCountArray.reduce((pre, current) => {
    return pre + current;
  }, 0);
  return { onlyOne: syncCount === 1 };
};

export const hasSyncBlock = (uuid: string): boolean => {
  const block = cache.blocks[uuid];
  if (!block) return false;
  if (block.type === BlockType.SYNC_CONTAINER || block.type === BlockType.SYNC_REFERENCE) {
    return true;
  }
  if (isPageLike(block.type)) return false;
  return block.subNodes.some((v) => {
    return hasSyncBlock(v);
  });
};

export const isSyncBlock = (type?: BlockType) => {
  return type === BlockType.SYNC_CONTAINER || type === BlockType.SYNC_REFERENCE;
};
/** 找到posId对应的顶层block */
export const getTopBlockId = (posId: string) => {
  const { blocks } = cache;
  let posBlock = blocks[posId];
  if (!posBlock) return;
  // 由于posId本身可能是page，所以拿它parent做比较
  let topBlock = posBlock;
  posBlock = blocks[posBlock.parentId];
  while (posBlock && posBlock.type !== BlockType.PAGE) {
    topBlock = posBlock;
    posBlock = blocks[posBlock.parentId];
  }
  return topBlock.uuid;
};

/** 通过url hash获取blockId，只获取普通块的blockId */
export const getBlockIdFromLocationHash = (hash: string) => {
  const blockId = hash.slice(1);
  if (!validate(blockId)) return;
  const block = cache.blocks[blockId];
  if (block && !isPageLike(block.type)) return blockId;
};
/**
 * 统计segments的字数
 */
export const computeSegmentsWordLength = (segments?: SegmentDTO[]) => {
  if (!segments) return 0;
  const computeSegmentsLength = (segment: SegmentDTO) => {
    switch (segment.type) {
      case TextType.DATE:
      case TextType.DATETIME:
        return 0;
      default:
        return segmentsToText([segment]).length;
    }
  };
  return segments.reduce((pre, cur) => {
    return pre + computeSegmentsLength(cur);
  }, 0);
};
/** 计算简单表格字数 */
export const computeSimpleTableWordLength = (uuid: string, blocks = cache.blocks) => {
  const tableBlock = blocks[uuid];
  if (tableBlock?.type !== BlockType.TABLE) {
    return 0;
  }
  const computeSimpleTableRowWordLength = (rowId: string) => {
    const rowBlock = blocks[rowId];
    if (rowBlock?.type !== BlockType.TABLE_ROW) {
      return 0;
    }
    const order = tableBlock.data.format?.tableBlockColumnOrder ?? [];
    return order.reduce((pre, curPropertyId) => {
      const segments = rowBlock.data.collectionProperties?.[curPropertyId];
      return pre + computeSegmentsWordLength(segments);
    }, 0);
  };
  return tableBlock.subNodes.reduce((pre, cur) => {
    return pre + computeSimpleTableRowWordLength(cur);
  }, 0);
};

/**
 * 统计块的字数,页面块只统计标题，不会继续统计subNodes
 */
export const computeBlockWordLength = (
  uuid: string,
  blocks = cache.blocks,
  alreadyCompute = {} as Record<string, boolean> // 已经统计过的块（引用页面可能互相引用导致死循环）
) => {
  const result = {
    word: 0,
    blocksNum: 0,
  };
  if (!uuid) return result;
  const block = blocks[uuid];
  if (!block) return result;
  if (alreadyCompute[uuid]) return result;
  result.blocksNum += 1;

  // 先算页面标题的，后算普通block的
  if (isPageLike(block.type)) {
    result.word =
      segmentsToText(block.data.segments).length + segmentsToText(block.data.description).length;
    return result;
  }
  alreadyCompute[uuid] = true;

  // 可编辑block字数
  if (isEditableType(block.type)) {
    result.word += computeSegmentsWordLength(block.data.segments);
  }
  // 块说明字数
  if (supportCaption(block)) {
    result.word += computeSegmentsWordLength(block.data.caption);
  }
  // 有subNode且非页面的块字数,这里不包含页面类型的block，因为最开始已经返回了。
  if (block.subNodes.length > 0) {
    block.subNodes.forEach((cur) => {
      const __total = computeBlockWordLength(cur, blocks, alreadyCompute);
      result.blocksNum += __total.blocksNum;
      result.word += __total.word;
    });
  }
  if (result.word > 100000) {
    // 某些场景下不知道为啥同步块内会有互相引用的情况(用户遇到)，导致这个方法死循环了，因此超过一定个数就return
    return result;
  }
  if (isLinkPageBlock(block) || block.type === BlockType.SYNC_REFERENCE) {
    const __total = computeBlockWordLength(block.data.ref?.uuid ?? '', blocks, alreadyCompute);
    result.word += __total.word;
    result.blocksNum += __total.blocksNum;
  }
  if (block.type === BlockType.TABLE) {
    result.word += computeSimpleTableWordLength(block.uuid);
  }

  return result;
};

export const findDescendantBlock = (
  uuid: string,
  types: BlockType[],
  filter?: (block: NextBlock) => boolean,
  blocks = getState().blocks
) => {
  const found: string[] = [];
  const block = blocks[uuid];
  if (block?.type !== undefined && types.includes(block?.type)) {
    if (!filter || filter?.(block)) {
      found.push(uuid);
    }
  }
  block?.subNodes.forEach((v) => {
    // 子页面不需要遍历
    if (!isPageLike(blocks[v]?.type)) {
      const foundSubNodes = findDescendantBlock(v, types, filter);
      found.push(...foundSubNodes);
    }
  });
  return found;
};

export const isInSyncBlock = (blockId: string) => {
  let id = blockId;
  while (id) {
    const block = cache.blocks[id];
    if (!block) return;
    const { type } = block;
    if (type === BlockType.SYNC_CONTAINER || type === BlockType.SYNC_REFERENCE) {
      return true;
    }
    id = block.parentId;
  }
  return false;
};
/**
 * 是否在分栏里面
 * @param uuid
 * @param stopAncestorId
 * @returns
 */
export const isInColumnList = (uuid: string, stopAncestorId: string): boolean => {
  const { blocks } = getState();
  const ancestors = getAncestors(uuid, blocks, stopAncestorId);

  const isColumnList = [...ancestors].some(
    (ancestor) => blocks[ancestor]?.type === BlockType.COLUMN_LIST
  );

  return isColumnList;
};

export const getAlignBlockIds = (selectedBlock: SelectBlock[]) => {
  const alignBlockIds = selectedBlock
    .filter((s) => {
      const sb = cache.blocks[s.blockId];
      const isRefImageBlock = sb?.type === BlockType.REFERENCE && sb.data.display === 'image';
      const isExternalImageBlock =
        sb?.type === BlockType.EXTERNAL_FILE && sb.data.display === 'image';
      const isImage =
        isRefImageBlock ||
        isExternalImageBlock ||
        (sb?.type === BlockType.FILE && sb.data.display === 'image');
      const isChart = sb?.type === BlockType.CHART && sb.data.format?.chartFormat?.ref?.uuid;
      return isChart || isImage || [BlockType.TEXTAREA, BlockType.HEADER].includes(sb?.type ?? 0);
    })
    .map((v) => v.blockId);
  return alignBlockIds;
};

/** 获取最后一次设置的代码块语言 */
export const getLastCodeLanguage = () => {
  const lastCodeLanguage = localStorage.getItem(LAST_SELECTED_CODE_LANGUAGE);
  return lastCodeLanguage;
};
export const isTextLikeBlock = (type: BlockType | undefined) => {
  switch (type) {
    case BlockType.TODO:
    case BlockType.MARK:
    case BlockType.HEADER:
    case BlockType.TEXTAREA:
    case BlockType.LIST:
    case BlockType.ORDER_LIST:
    case BlockType.FOLD_LIST:
    case BlockType.QUOTE: {
      return true;
    }
    default:
  }
  return false;
};
/** 获取第一个类文本块 */
export const getFirstTextBlockId = (id: string, blocks = getState().blocks): string | undefined => {
  const block = blocks[id];
  if (!block) return;
  if (isTextLikeBlock(block.type) && segmentsToText(block.data.segments).trim()) {
    return id;
  }
  for (const subId of block.subNodes) {
    if (isPageLike(blocks[subId]?.type)) continue;
    const result = getFirstTextBlockId(subId);
    if (result) return result;
  }
};

export const getFirstTextByBlock = (id: string) => {
  const _id = getFirstTextBlockId(id);
  if (!_id) return;
  return segmentsToText(cache.blocks[_id]?.data.segments);
};
/**
 * 一层层往上找，找到第一个开启分享并且允许订阅的页面
 */
export const findFirstAllowSubscribePage = (uuid: string) => {
  const getAllowSubscribePage = (uuid: string): string | undefined => {
    const block = cache.blocks[uuid];
    if (!block) return;
    if (!isPageLike(block?.type)) {
      return;
    }
    const publicPermissions = block?.permissions.filter((p) => p.type === PermissionType.PUBLIC);
    const allowSubscribe = publicPermissions?.some((p) => p.allowSubscribe);
    if (allowSubscribe) return uuid;
    return getAllowSubscribePage(block.parentId);
  };
  const result = getAllowSubscribePage(uuid);
  return result;
};

export const checkHasCollectionData = (collectionId: string) => {
  const collection = cache.blocks[collectionId];
  if (!collection) return false;
  const hasChild = collection.subNodes.every((id) => {
    const child = cache.blocks[id];
    return !!child;
  });
  if (!hasChild) {
    return false;
  }
  const hasViewData = collection.views?.every((viewId) => cache.collectionViews[viewId]);
  if (!hasViewData) return false;
  //  如果collection，subNode和collectionview都有数据了，那么就不需要重复请求了
  return true;
};
