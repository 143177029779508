import { useCallback } from 'react';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { $appUiStateCache } from 'src/services/app';
import { useOpenCreateBlockMenuList } from '../public/use-create-block-menu-list';
import isHotkey from 'is-hotkey';

export const useSlashKey = (uuid: string) => {
  const openCreateBlockMenuList = useOpenCreateBlockMenuList();
  const getEditorModel = useGetOrInitEditorModel();
  const syncId = useSyncId();
  const slashKey = (event?: KeyboardEvent) => {
    if ($appUiStateCache.$ignoreSlash) return;
    const editorModel = getEditorModel(uuid);
    if (!editorModel) return;

    const { selection } = editorModel;
    if (!selection) return;
    if (!selection.isCollapsed) return;
    // 俄罗斯的slash按键是. 这里做特殊处理
    if (event && isHotkey(['.'])(event)) {
      event.preventDefault();
      // 插入'/'
      editorModel.inputInsert('/', 'input');
    }

    const popcorn = {
      getBoundingClientRect() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return editorModel.getBoundingClientRectOfRange(
          selection.focusOffset,
          selection.focusOffset
        )!;
      },
    };

    openCreateBlockMenuList({
      popcorn,
      offset: [4, 8],
      blockId: uuid,
      slash: true,
      syncId,
    });
  };

  return useCallback(slashKey, [getEditorModel, openCreateBlockMenuList, syncId, uuid]);
};
