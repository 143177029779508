import { useStateRef } from '@flowus/common/hooks/react-utils';
import { useDeepCompareMemo } from '@react-hookz/web';
import { useDeepCompareEffect } from 'ahooks';
import { useCallback } from 'react';
import { createModel, useModel } from 'src/common/create-model';
import { useBitablePageSize } from 'src/hooks/collection-view/use-collection-view';
import { getState } from 'src/redux/store';
import { $appUiStateCache } from 'src/services/app';
import { $searchParams } from 'src/utils';
import { AUTO_LOAD_COUNT, MAX_BITABLE_PAGE_SIZE } from './const';
import { useBitable } from './context';

export const getPropertySegments = (
  recordId: string,
  propertyId: string,
  blocks = getState().blocks
) => {
  return blocks[recordId]?.data.collectionProperties?.[propertyId];
};

const useBitableLoadMore = (props: { disable?: boolean; recordIds?: string[] }) => {
  const { viewId } = useBitable();
  const { disable, recordIds: originRecordIds = [] } = props;
  const pageSize = useBitablePageSize(viewId);
  const isMax = pageSize === MAX_BITABLE_PAGE_SIZE;

  /** 总共有多少 count */
  const totalCount = originRecordIds.length;

  /** 当前显示 count */
  const [count, setCount, countRef] = useStateRef(AUTO_LOAD_COUNT);
  const [maxAutoLoadCount, setMaxAutoLoadCount, maxAutoLoadCountRef] = useStateRef(pageSize);

  const loadMore = useCallback(
    (isAutoLoad?: boolean) => {
      if (disable) {
        return;
      }

      if (countRef.current >= totalCount) {
        return;
      }

      if (!isAutoLoad) {
        setMaxAutoLoadCount(maxAutoLoadCountRef.current + pageSize);
      }

      setCount(countRef.current + AUTO_LOAD_COUNT);
    },
    [disable, countRef, totalCount, setCount, setMaxAutoLoadCount, maxAutoLoadCountRef, pageSize]
  );

  useDeepCompareEffect(() => {
    if (pageSize !== MAX_BITABLE_PAGE_SIZE) {
      setCount(pageSize);
      setMaxAutoLoadCount(pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  // 渲染的ids
  const recordIds = useDeepCompareMemo(() => {
    if (disable || $searchParams.print) return originRecordIds;

    const ids = originRecordIds.slice(0, count);
    const newCreateRecord = $appUiStateCache.$newCreatedRecord;
    if (newCreateRecord) {
      if (
        viewId === newCreateRecord.viewId &&
        originRecordIds.includes(newCreateRecord.id) &&
        !ids.includes(newCreateRecord.id)
      ) {
        ids.push(newCreateRecord.id);
      }
    }
    return ids;
  }, [disable, originRecordIds, count, viewId]);

  const context = useDeepCompareMemo(
    () => ({
      loadMore,
      showMore: Boolean(!disable && !isMax && count < totalCount && pageSize < totalCount),
      recordIds,
      state: count,
      autoLoad: Boolean(
        !disable && (isMax ? isMax : count < maxAutoLoadCount) && count < totalCount
      ),
    }),
    [disable, isMax, loadMore, pageSize, recordIds, count, totalCount]
  );

  return context;
};

export const BitableLoadMoreContext = createModel(useBitableLoadMore);

export const useBitableLoadMoreContext = () => useModel(BitableLoadMoreContext);
