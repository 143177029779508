import type {
  AIChatDTO,
  BlockDTO,
  BlockType,
  ConversationDetailDTO,
  QAQueryOptionDTO,
  QAQuestionDTO,
  SearchEngineResultDTO,
} from '@next-space/fe-api-idl';
import { QASearchSource } from '@next-space/fe-api-idl';
import type { ReactNode } from 'react';

export enum ReferencePageType {
  PAGE = 'page',
  FILE = 'file',
}

export const DEFAULT_REFERENCE_OPTIONS = QASearchSource.AI_MODEL;

export interface CreateChatParams {
  keywords: string;
  parentId?: string;
  spaceId: string;
  qaQueryOption?: QAQueryOptionDTO;
}

// ------------------------------------------------------------

export enum ChatStatus {
  /** 默认状态 */
  DEFAULT = 0,
  /** 初始化，整个都在加载中 */
  LOADING = 1,
  /** 发送消息时，还没开始触发 AI 流 */
  CREATING = 2,
  /** 发送消息时，触发 AI 流 */
  SENDING = 3,
}

export enum ChatUIStyle {
  DEFAULT = 'default',
  LITE = 'lite',
}

export enum SideBarType {
  OUTLINE = 'outline',
  LITE_CHAT = 'lite-chat',
  LITE_CHAT_SKELETON = 'lite-chat-skeleton',
}

export interface SaveArticleParams {
  ai: string;
  user: string;
  saveType: BlockType.PAGE | BlockType.MIND_MAPPING_PAGE;
}

export interface ConversationContextType {
  conversationId: string;
  style: ChatUIStyle;
  spaceId: string;
  parentId?: string;
  sideBarWidth?: number;
  sideBarType?: SideBarType;
  navigateToChat: (id: string) => void;
  updateContext: (context: Partial<Omit<ConversationContextType, 'updateContext'>>) => void;
  onSaveArticle: (res: SaveArticleParams) => void;
  insertParagraph?: (res: Omit<SaveArticleParams, 'saveType'>) => void;
  renderPageIcon?: (block: BlockDTO) => React.ReactNode;
  openUpgrade?: () => void;
  onSummarize?: () => void;
  onRenderBlockPreview?: (id: string) => ReactNode;
  onLoadConversation?: (conversation: ConversationDetailDTO) => void;
  navbar?: () => ReactNode;
}

export interface RefDataDTO extends SearchEngineResultDTO {
  id?: string;
  type: 'block' | 'web';
}

export interface QuestionContextType {
  conversationId: string;
  questionId: string;
  index: number;
  totalIndex: number;
  curChatIndex: number;
  totalChats: number;
  handlePrevious: () => void;
  handleNext: () => void;
}

export interface ConversationEditStore {
  keywords: string;
  qaQueryOption: QAQueryOptionDTO;
}

export const AI_PAGE_ID = 'ai-page';

export enum EditorNodeType {
  CODE_BLOCK = 'codeBlock',
  MENTION = 'mention',
  HEADING = 'heading',
}

export enum SearchPageStatus {
  LOADING = 'loading',
  DEFAULT = 'default',
}

export interface MentionItem {
  id: string;
  label: string;
}

export const defaultConversation: Required<ConversationDetailDTO> = {
  questions: [],
  question: '',
  id: AI_PAGE_ID,
  createdAt: Date.now(),
  updatedAt: Date.now(),
  spaceId: '',
  parentId: '',
};

export const defaultQuestion: Required<QAQuestionDTO> = {
  id: AI_PAGE_ID,
  question: '',
  conversationId: AI_PAGE_ID,
  chatList: [],
  createdAt: Date.now(),
};

export const defaultConversationEditStore: Required<ConversationEditStore> = {
  keywords: '',
  qaQueryOption: {
    searchBlocks: [],
    qaSearchSource: DEFAULT_REFERENCE_OPTIONS,
    networkSearch: true,
  },
};

export const defaultChat: Required<AIChatDTO> = {
  uuid: AI_PAGE_ID,
  ai: '',
  user: '',
  createdBy: '',
  quote: '',
  links: [],
  createdAt: Date.now(),
  resultData: {
    engineResult: [],
    vectorResult: [],
    suggestions: [],
  },
  qaQueryOption: defaultConversationEditStore.qaQueryOption,
};
