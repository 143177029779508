import { TextType, type SegmentDTO } from '@next-space/fe-api-idl';

const COLORS = [
  'grey',
  'brown',
  'orange',
  'yellow',
  'green',
  'blue',
  'purple',
  'pink',
  'red',
  'black',
];
const BACKGROUND_COLORS = COLORS.map((v) => `${v}_background`);
export const arrayToStyle = (styles: string[] | undefined) => {
  const segment: SegmentDTO = {
    type: TextType.TEXT,
    text: '',
    enhancer: {},
  };
  if (styles === undefined) return [segment];
  if (styles === null) return [segment];
  if (styles.length === 0) return [segment];
  segment.text = styles?.[0] ?? '';
  styles?.slice(1).forEach((v) => {
    switch (v) {
      case 'b': {
        segment.enhancer.bold = true;
        break;
      }
      case 'u': {
        segment.enhancer.underline = true;
        break;
      }
      case 'i': {
        segment.enhancer.italic = true;
        break;
      }
      case 'c': {
        segment.enhancer.code = true;
        break;
      }
      case 's': {
        segment.enhancer.lineThrough = true;
        break;
      }
      default:
        if (COLORS.includes(v)) {
          segment.enhancer.textColor = v;
        } else if (BACKGROUND_COLORS.includes(v)) {
          segment.enhancer.backgroundColor = v.substring(0, v.length - '_backgound'.length - 1);
        }
        break;
    }
  });
  return [segment];
};
