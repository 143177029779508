import { cx } from '@flowus/common/cx';
import { builtins, builtinsEn } from '@flowus/formula';
import type { Editor, Position, TextMarker } from 'codemirror';
import CodeMirror from 'codemirror';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/matchtags.js';
import 'codemirror/addon/mode/simple';
import 'codemirror/addon/search/match-highlighter.js';
import 'codemirror/lib/codemirror.css';
import isHotkey from 'is-hotkey';
import * as _ from 'lodash-es';
import { css } from 'otion';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { useUpdatedRef } from '@flowus/common/hooks/react-utils';
import type { CollectionSchema } from '@next-space/fe-api-idl';
import ReactDOM from 'react-dom';
import { Icon } from 'src/common/components/icon';
import { ICON_MAP } from 'src/bitable/const';
import { getIsDarkMode } from '@flowus/common';

export const builtinsWords = [
  'prop',
  ..._.flatMap(__BUILD_IN__ ? builtinsEn : builtins, (it) => it.list).map((it) => it.title),
];

CodeMirror.defineSimpleMode('formula', {
  start: [
    { regex: /"([^\\"]|\\.)*"?/, token: 'string' },
    { regex: /\b(?:pi|e|true|false)\b/, token: 'atom' },
    { regex: /(?:0|[1-9][0-9]*)(?:[.][0-9]+)?(?:[eE][+-]?(?:0|[1-9][0-9]*))?/, token: 'number' },
    {
      regex: new RegExp(`\\b(?:${builtinsWords.join('|')})\\b`),
      token: 'builtin',
    },
    { regex: /\b(?:[A-Za-z_][A-Za-z0-9_]*)\b/, token: 'variable' },
  ],
});

export interface FormulaCodeEditorHandle {
  cm(): Editor;
}

export const getSearchWord = (cm: Editor) => {
  const doc = cm.getDoc();
  const content = doc.getValue();
  let cursor = doc.indexFromPos(cm.getCursor());

  let word = '';
  if (/[A-Za-z0-9_]/.test(content[cursor] ?? '')) {
    return null;
  }

  while (cursor > 0) {
    const chr = content[cursor - 1] ?? '';
    if (!/[A-Za-z0-9_]/.test(chr)) {
      break;
    }
    word = chr + word;
    cursor -= 1;
  }

  if (word === '' || /[0-9]/.test(word.charAt(0))) return null;

  // check if cursor in string literal
  let checkCursor = 0;
  while (checkCursor < cursor) {
    const tempIndex = content.slice(checkCursor).indexOf('"');
    const quoteStart = checkCursor + tempIndex;
    if (tempIndex === -1 || cursor <= quoteStart) {
      break;
    }
    const match = /^"([^"\\]|\.)*"/.exec(content.slice(quoteStart));
    const quoteEnd =
      match == null ? content.length : quoteStart + match.index + (match[0]?.length ?? 0);
    if (cursor < quoteEnd) {
      return null;
    }
    checkCursor = quoteEnd;
  }

  return { cursor, word };
};

export const FormulaCodeEditor = forwardRef(
  (
    props: {
      className?: string;
      initialValue: string;
      autoFocus?: boolean;
      placeholder?: string;
      maxHeight?: string;
      readOnly?: boolean;
      onChange?: () => void;
      schema: Record<string, CollectionSchema> | undefined;
    },
    ref
  ) => {
    const isDarkMode = useIsDarkMode();
    const divRef = useRef<HTMLDivElement>(null);
    const propsRef = useUpdatedRef(props);
    const editorRef = useRef<Editor>();
    useImperativeHandle(ref, () => {
      return {
        cm() {
          return editorRef.current;
        },
      };
    });
    useEffect(() => {
      const div = divRef.current;
      if (!div) return;

      const { initialValue, placeholder, readOnly = false, autoFocus = false } = propsRef.current;
      const editor = CodeMirror(div, {
        value: initialValue,
        mode: 'formula',
        autoRefresh: true,
        viewportMargin: Infinity,
        autoCloseBrackets: true,
        placeholder,
        readOnly,
        lineWrapping: true,
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        matchTags: { bothTags: true },
        matchBrackets: { strict: true },
        theme: isDarkMode ? 'ayu-dark' : 'default',
      });
      editorRef.current = editor;
      if (autoFocus) {
        // editor.focus();
        const doc = editor.getDoc();
        editor.setCursor(doc.posFromIndex(Infinity));
      }
      const ENABLE_REPLACE = false;
      editor.on('beforeChange', (_cm, change) => {
        const cleanText = (text: string) => {
          const iterParts = function* (str: string): Generator<[boolean, string]> {
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            const reStr = /("(?:[^"\\]|\\.)*"?)/gy;
            let lastIndex = 0;
            while (true) {
              reStr.lastIndex = lastIndex;
              const m = reStr.exec(str);
              if (m == null) {
                break;
              }
              if (m.index > lastIndex) {
                yield [false, str.slice(lastIndex, m.index)];
              }
              yield [true, m[0]!];
              lastIndex = m.index + m[0]!.length;
            }
            if (lastIndex < str.length) {
              yield [false, str.slice(lastIndex)];
            }
          };
          let res = '';
          for (const [lit, str] of iterParts(text)) {
            if (lit) {
              // 不处理公式中的字符串字面量
              res += str;
            } else {
              res += str
                // “, ”
                .replace(/[\u201c\u201d]/g, '"')
                // en dash, em dash, minus sign
                .replace(/[\u2013\u2014\u2212]/g, '-')
                // ∗, ×
                .replace(/[\u2217\u00d7]/g, '*')
                // ÷
                .replace(/[\u00f7]/g, '/')
                // 全角转半角
                .replace(/[\u3000]/g, ' ')
                .replace(/[\uff01-\uff5e]/g, (c) =>
                  String.fromCharCode(c.charCodeAt(0) - (0xff01 - 0x0021))
                );
            }
          }
          return res;
        };
        if (ENABLE_REPLACE) {
          change.update?.(change.from, change.to, change.text.map(cleanText));
        }
      });
      const hintElm = document.createElement('span');
      hintElm.className = 'widget-autocomplete';
      let currCursor: Position | null = null;
      let currMark: TextMarker | null = null;
      let currHint: string | null = null;
      const propMarkers: TextMarker[] = [];
      const clear = () => {
        hintElm.innerText = '';
        currMark?.clear();
        currMark = null;
        currCursor = null;
        currHint = null;
      };
      const clearPropMarkers = () => {
        propMarkers.forEach((it) => it.clear());
      };
      const updatePropMarkers = (cm: Editor) => {
        cm.startOperation();
        clearPropMarkers();
        const text = cm.getDoc().getValue();
        const re = /prop\("([^"]+)"\)/g;
        let match;
        while ((match = re.exec(text))) {
          const from = cm.getDoc().posFromIndex(match.index);
          const to = cm.getDoc().posFromIndex(match.index + match[0]!.length);
          const propName = match[1] ?? '';
          const propKey = _.findKey(props.schema ?? {}, (value) => {
            return value.name === propName;
          });
          if (propKey != null) {
            const prop = props.schema![propKey]!;
            const span = document.createElement('span');
            span.className = getIsDarkMode() ? 'cm-mark-formula-prop-dark' : 'cm-mark-formula-prop';
            ReactDOM.render(
              <>
                <Icon
                  style={{ color: '#91918f' }}
                  className="mr-1"
                  size="small"
                  name={ICON_MAP[prop.type]}
                />
                {propName}
              </>,
              span
            );
            propMarkers.push(
              cm.markText(from, to, {
                replacedWith: span,
                atomic: true,
              })
            );
          }
        }
        cm.endOperation();
      };
      updatePropMarkers(editor);
      editor.on('changes', (cm) => {
        cm.startOperation();
        clear();
        currCursor = cm.getCursor();
        const result = getSearchWord(cm);
        const prefix = result?.word;
        if (prefix) {
          const candidate = builtinsWords.find((it) => it.startsWith(prefix));
          if (candidate && candidate !== prefix) {
            currHint = candidate.slice(prefix.length);
            currMark = cm.setBookmark(currCursor, {
              widget: hintElm,
              insertLeft: true,
            });
            hintElm.innerText = currHint;
          }
        }
        cm.endOperation();
        updatePropMarkers(cm);
      });
      editor.on('changes', () => {
        propsRef.current.onChange?.();
      });
      editor.on('cursorActivity', (cm) => {
        const cursor = cm.getCursor();
        if (currCursor != null) {
          if (cursor.line !== currCursor.line || cursor.ch !== currCursor.ch) {
            clear();
          }
        }
      });
      editor.on('keydown', (cm, event) => {
        if (currCursor != null) {
          if (currHint != null) {
            if (isHotkey('tab')(event) || isHotkey('right')(event)) {
              cm.replaceRange(currHint, currCursor, currCursor, '+input');
              event.preventDefault();
            }
          }
        }
      });
      return () => {
        const range = new Range();
        range.selectNodeContents(div);
        range.deleteContents();
      };
    }, [isDarkMode, propsRef, props.schema]);
    const { className } = props;
    return (
      <div
        ref={divRef}
        className={cx(
          className,
          css({
            selectors: {
              '& div.CodeMirror': {
                height: 'auto',
                background: 'transparent',
                color: 'var(--black)',
              },
              '.CodeMirror-wrap pre': {
                wordBreak: 'break-word',
              },
              '& div.CodeMirror-scroll': {
                maxHeight: props.maxHeight,
              },
              '& .widget-autocomplete': {
                color: 'var(--grey3)',
              },
              '& .CodeMirror-empty': {
                color: 'var(--grey4)',
              },
              '& .CodeMirror-cursor': {
                borderLeftWidth: '1px !important',
                display: props.readOnly ?? false ? 'none !important' : undefined,
              },
              '& .CodeMirror-focused .CodeMirror-selected': {
                background: 'var(--selection) !important',
              },
              '& .CodeMirror-line::selection, & .CodeMirror-line > span::selection, & .CodeMirror-line > span > span::selection':
                {
                  background: 'var(--selection) !important',
                },
              '& .CodeMirror pre.CodeMirror-line, & .CodeMirror pre.CodeMirror-line-like': {
                fontFamily:
                  'SFMono-Regular, Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace',
              },
              '& .cm-mark-error': {
                backgroundPosition: 'left bottom',
                backgroundRepeat: 'repeat-x',
                backgroundImage:
                  'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAAXNSR0IArs4c6QAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9sJDw4cOCW1/KIAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAHElEQVQI12NggIL/DAz/GdA5/xkY/qPKMDAwAADLZwf5rvm+LQAAAABJRU5ErkJggg==")',
              },
              '& .cm-mark-formula-prop': {
                boxSizing: 'content-box',
                height: '16px',
                lineHeight: '16px',
                fontSize: '12px',
                display: 'inline-flex',
                alignContent: 'center',
                backgroundColor: '#e3e2e0',
                padding: '2px 4px',
                borderRadius: '4px',
                verticalAlign: 'middle',
                marginLeft: '1px',
                marginRight: '1px',
                marginTop: '-2px',
                marginBottom: '-1px',
              },
              '& .cm-mark-formula-prop-dark': {
                boxSizing: 'content-box',
                height: '16px',
                lineHeight: '16px',
                fontSize: '12px',
                display: 'inline-flex',
                alignContent: 'center',
                backgroundColor: 'black',
                padding: '2px 4px',
                borderRadius: '4px',
                verticalAlign: 'middle',
                marginLeft: '1px',
                marginRight: '1px',
                marginTop: '-2px',
                marginBottom: '-1px',
                color: 'grey',
              },
              '& .CodeMirror-matchingbracket': {
                outline: '1px solid var(--grey4)',
              },
            },
          })
        )}
      ></div>
    );
  }
);
