import { cx } from '@flowus/common/cx';
import { sleep } from '@flowus/common/async';
import { BlockType } from '@next-space/fe-api-idl';
import type { IEditorModel, IMarker } from '@next-space/fe-inlined';
import type { Instance } from '@popperjs/core';
import isHotkey from 'is-hotkey';
import { last } from 'lodash-es';
import { css } from 'otion';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { message } from 'src/common/components/message';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { PRIORITY_DIALOG, globalListenerHelper } from 'src/common/utils/global-listener-helper';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import type { BlockEditor as BlockEditor0 } from 'src/editor/editor/uikit/editor';
import { segmentsToText } from 'src/editor/utils/editor';
import { isBuildIn } from 'src/env';
import { useDebounceElementSize } from 'src/hooks/public/use-debounce-element-size';
import { useCurrentSpace } from 'src/hooks/space';
import { useUpload } from 'src/hooks/space/use-upload';
import { useTransaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import { removeBlock } from 'src/redux/managers/block/remove';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache, getState } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { getToken } from 'src/utils/get-next-auth';
import { useGetPageId } from 'src/utils/getPageId';
import { selectedBlocksToIds } from 'src/utils/select-block-util';
import { PageScrollRefContext } from 'src/views/main/page-doc/context';
import { PageScene, PageSceneContext } from 'src/views/main/scene-context';
import {
  isAiMindMap,
  isAiBitable,
  AIEditorScene,
  AIEditType,
  closeAIEditorWarningModalId,
  NeedWaitingResultEditType,
} from './const';
import { fixPrompt } from './fix-promot';
import { getBitableContent, getBlockIdsContent } from './get-block-ids-content';
import { handleEditTypeEvent } from './handle-edit-type';
import { handleStrPiece } from './handle-str-piece';
import { LogMap } from './log';
import {
  getAIMenuItems,
  getEditTypeMapPrompt,
  SpecialWriteType,
  translateEditType,
  TRANSLATE_LANGUAGE,
} from './menu-items';
import { checkMindMap, getAllNodeTypes, getLastNodeId, getNodeType, parseAIMessage } from './utils';
import { useIsAiPlan } from 'src/services/capacity';
import { useOpenSettingModal } from 'src/views/main/setting-modal/use-open-setting-modal';
import { OpenSettingFrom } from 'src/views/main/setting-modal/type';
import { PRODUCT_NAME } from 'src/const/title';
import { PromptEditor } from '../prompt-editor';
import { usePromptStore } from '../prompt-editor/store';
import { listViewNormalClassName } from 'src/common/components/list-view/helper';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useOpenUpgradeAiModal } from 'src/components/select-upgrade-plan/upgrade-ai';
import { useIsGuest } from 'src/hooks/share/use-permission-utils';

interface Props {
  closeSelf: ModalSchema.CloseModalType;
  editorScene: AIEditorScene;
  blockId: string;
  editType?: AIEditType;
  popper?: React.MutableRefObject<Instance | null>;
  model?: IEditorModel;
  isInRight: boolean;
  selection?: { start: number; end?: number };
  popcorn: ModalSchema.PopcornType;
  from?: string;
  isBitable?: boolean;
  isMindMap?: boolean;
  isDescription?: boolean;
  showInput?: boolean;
  defaultCustomPrompt?: string;
}

let BlockEditor: typeof BlockEditor0;

export const antiCycleSet_BlockEditor = (BlockEditor0: typeof BlockEditor) => {
  BlockEditor = BlockEditor0;
};
interface QuickSettingProps {
  model?: IEditorModel;
}
const QuickSetting: FC<QuickSettingProps> = (props) => {
  const { model, children, ...rest } = props;
  const openModal = useOpenModal();
  return (
    <div {...rest} className="mb-1 px-1">
      <div
        className="w-full py-1 px-3 flex items-center justify-between"
        onClick={() => {
          model?.performChange((ctx) => {
            ctx.select(0, 0);
          });
          openModal.modal({
            content: () => {
              return <PromptEditor />;
            },
          });
        }}
      >
        <div className="text-t2 text-grey3">编辑快捷操作</div>
        <Icon className="animate-click text-grey3" name={'IcSettings'} size="middle" />
      </div>
    </div>
  );
};

export const AIEditor: FC<Props> = (props) => {
  const {
    closeSelf,
    editorScene,
    editType,
    popper,
    popcorn,
    blockId,
    selection,
    model,
    isInRight,
    from,
    isBitable,
    isMindMap,
    isDescription,
    defaultCustomPrompt,
    showInput = true,
  } = props;
  const [scene, setEditScene] = useState(editorScene);
  const isAiPro = useIsAiPlan();
  const openSettingModal = useOpenSettingModal();
  const isBitableCommand = useRef(editType ? isAiBitable.has(editType) : false);
  const isMindMapCommand = useRef(editType ? isAiMindMap.has(editType) : false);
  const [prompt, setPrompt] = useState(() => {
    if (editType) {
      bizTracker.event('ai_trigger', { ai_type: LogMap[editType], from });
    }
    return getEditTypeMapPrompt(editType ?? '')?.prompt;
  });

  const [isWaitingResult, setIsWaiting] = useState(
    editType && NeedWaitingResultEditType.has(editType)
  );
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const pageId = useGetPageId();
  const editTypeRef = useRef(editType);
  const [showInputComponent, setShowInputComponent] = useState(showInput);
  const transaction = useTransaction();
  const pageContentWidth = useDebounceElementSize(
    querySelectorFromMainContent(`.next-space-page .page-header .group`, isInRight)
  ).width;

  const selectedText = useRef('');
  // 继续写的时候基于上一次写到的块继续写
  const currentBlockId = useRef('');
  // 记录每次创建块的类型，创建思维导图需要根据类型判断当前是兄弟节点还是字节点，相同就是兄弟节点，不相同就是字节点或者父节点类型
  const mindNodeTypes = useRef<string[]>([]);
  const selectedBlockIds = useRef<string[]>(
    scene === AIEditorScene.BlockSelected ? selectedBlocksToIds(cache.ui.selectedBlocks) : []
  );
  const cancelRequest = useRef<AbortController>();
  const openModal = useOpenModal();
  const modalContainer = useRef<HTMLDivElement | null>(null);
  const resultContainer = useRef<HTMLDivElement | null>(null);
  const marker = useRef<IMarker>();
  const currentSpace = useCurrentSpace();
  const allLine = useRef<string[]>([]);
  const codeStartLineNum = useRef(-Infinity); // 表示在 allLine 中，代码块第一行 ``` 开始的索引
  const archiveResult = useRef<string[]>([]);
  const tempPageId = useRef('');
  const [showTempPage, setShowTempPage] = useState(false);
  const uploadFile = useUpload();
  const getEditorModel = useGetOrInitEditorModel();
  const [showListView, setShowListView] = useState(false);
  const customPrompt = useRef<string | undefined>(defaultCustomPrompt);
  const [placeholder, setPlaceHolder] = useState<string | undefined>(defaultCustomPrompt);
  const openUpgradeAiModal = useOpenUpgradeAiModal();
  const isGuest = useIsGuest();

  useEffect(() => {
    if (scene === AIEditorScene.PageEmpty) return;

    // 刚打开弹窗的时候修正定位
    const editorContainer = modalContainer.current;
    if (!editorContainer) return;
    const pageContainer = querySelectorFromMainContent(`.next-space-page`, isInRight);
    if (!pageContainer) return;

    const instance = popper?.current;
    if (!instance) return;

    const editorHeight = editorContainer.clientHeight + 10;
    const popcornRect = popcorn.getBoundingClientRect();
    const pageContainerRect = pageContainer.getBoundingClientRect();
    const offsetContainer = popcornRect.bottom - pageContainerRect.top;

    if (pageContainer.clientHeight - offsetContainer > editorHeight) {
      return;
    }

    const offsetContentContainer = offsetContainer + pageContainer.scrollTop;
    const newScrollTop = offsetContentContainer - (pageContainer.clientHeight - editorHeight);
    pageContainer.scrollTo(0, newScrollTop);
    void instance.update();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popper?.current]);

  useEffect(() => {
    if (scene !== AIEditorScene.TextSelected) return;
    if (!model) return;

    marker.current = model.mark(selection?.start ?? 0, selection?.end ?? 0, {
      className: css({ backgroundColor: 'var(--selection)' }),
    });

    return () => {
      marker.current?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitCurrBlock = useCallback(() => {
    // 当数据接收完或者被中止的时候，提交当前 block 的 op
    if (
      scene === AIEditorScene.PageEmpty ||
      scene === AIEditorScene.PageHasContent ||
      scene === AIEditorScene.PageContentWroteByAI
    ) {
      transaction(() => {
        const currBlock = cache.blocks[currentBlockId.current];
        if (currBlock) {
          updateBlock(currentBlockId.current, { type: currBlock.type, data: currBlock.data });
        }
      });
    }
  }, [scene, transaction]);

  const refreshMindMapData = useCallback(() => {
    const { isMindMapPage, isInMindMap } = checkMindMap(blockId);
    if (isMindMapPage) {
      const lastNodeId = getLastNodeId(blockId);
      mindNodeTypes.current = getAllNodeTypes(blockId);
      currentBlockId.current = lastNodeId;
      isMindMapCommand.current = true;
    } else if (isInMindMap) {
      mindNodeTypes.current = getAllNodeTypes(blockId);
      currentBlockId.current = blockId;
      isMindMapCommand.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockId, scene]);
  // 准备一些思维导图继续写作的前置数据
  useEffect(() => {
    refreshMindMapData();
  }, [refreshMindMapData]);

  /** 尝试关闭输入框 */
  const closeInput = useCallback(() => {
    if (isWaitingResult || tempPageId.current) {
      openModal.warning({
        modalId: closeAIEditorWarningModalId,
        title: '是否放弃当前AI处理结果？',
        confirm() {
          removeTempPage();
          if (isWaitingResult) {
            submitCurrBlock();
          }
          cancelRequest.current?.abort();
          closeSelf();
          setShowListView(false);
        },
      });
    } else {
      closeSelf();
      setShowListView(false);
    }
    const editorModal = getEditorModel(blockId);
    void editorModal?.requestFocus();
  }, [blockId, closeSelf, getEditorModel, isWaitingResult, openModal, submitCurrBlock]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (modalContainer.current?.contains(target) || target.closest('[data-list-item]')) {
        return;
      }
      closeInput();
    };

    // 处理关闭
    globalListenerHelper.addEventListener('mouseup', handleClick);
    return () => {
      globalListenerHelper.removeEventListener('mouseup', handleClick);
    };
  }, [closeSelf, closeInput, isWaitingResult, openModal, transaction, getEditorModel, blockId]);

  useEffect(() => {
    // 存储被选中的文字
    const { ui, blocks } = getState();

    let str = '';
    if (scene === AIEditorScene.BlockSelected) {
      const ids = selectedBlocksToIds(ui.selectedBlocks);
      str = getBlockIdsContent(ids);
    }
    if (scene === AIEditorScene.TextSelected) {
      const { description, segments } = blocks[blockId]?.data ?? {};
      const blockStr = segmentsToText(isDescription ? description : segments);
      str = blockStr.slice(selection?.start, selection?.end);
    }
    selectedText.current = str;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!editType) return;

    if (editType === AIEditType.OtherLanguage) return;

    const translate = new Set(TRANSLATE_LANGUAGE.map((item) => item.editType));
    if (
      editType === AIEditType.ContinueWrite ||
      editType === AIEditType.Summarize ||
      editType === AIEditType.Summary ||
      editType === AIEditType.Explain ||
      translate.has(editType)
    ) {
      handleEditType(editType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const focusInput = () => {
    const inputNode = inputRef.current;
    if (inputNode) {
      void sleep(0).then(() => {
        inputNode.setSelectionRange(inputNode.value.length, inputNode.value.length);
        inputNode.focus();
      });
    }
  };

  useEffect(() => {
    focusInput();
  }, []);

  const stopWriting = useCallback(() => {
    cancelRequest.current?.abort();
    setIsWaiting(false);
    setPrompt('');
    setPlaceHolder('');
    focusInput();

    if (tempPageId.current) {
      if (scene === AIEditorScene.BlockSelected) {
        setEditScene(AIEditorScene.BlockSelectedAndWroteByAI);
      }
      if (scene === AIEditorScene.TextSelected) {
        setEditScene(AIEditorScene.TextSelectedAndWroteByAI);
      }
    }

    if (
      scene === AIEditorScene.PageEmpty ||
      scene === AIEditorScene.PageHasContent ||
      scene === AIEditorScene.PageContentWroteByAI
    ) {
      setEditScene(AIEditorScene.PageContentWroteByAI);

      // 提交当前已经写入的部分
      submitCurrBlock();
    }
  }, [scene, submitCurrBlock]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isHotkey('esc')(event)) {
        if (isWaitingResult) {
          stopWriting();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isWaitingResult, stopWriting]);

  const handleEditType = (editType: AIEditType) => {
    if (
      editType === AIEditType.Replace ||
      editType === AIEditType.Insert ||
      editType === AIEditType.Close
    ) {
      bizTracker.event('ai_finish', {
        ai_type: LogMap[editTypeRef.current ?? ''] ?? editType,
        from,
      });
    } else {
      bizTracker.event('ai_trigger', { ai_type: LogMap[editType], from });
    }
    refreshMindMapData();
    handleEditTypeEvent({
      pageId,
      blockId,
      prompt: customPrompt.current ?? '',
      tempPageId,
      allLine,
      archiveResult,
      selectedText,
      selectedBlockIds,
      editTypeRef,
      editType,
      scene,
      fetchAIResult,
      setPlaceHolder,
      setPrompt,
      getSelectedContent,
      removeTempPage,
      closeSelf,
      selection,
      model,
      isBitable,
      isMindMapPage: isMindMap,
      isDescription,
      getEditorModel,
    });
  };

  const isBitablePage = location.href.includes(blockId);
  const onItemClick = (item: any) => {
    const { data } = item;
    if (data.prompt && data.editType === AIEditType.Custom) {
      // custom prompt
      customPrompt.current = data?.prompt;
    } else {
      customPrompt.current = undefined;
    }

    if (isBitableCommand.current && data.editType !== AIEditType.ContinueWrite) {
      isBitableCommand.current = false;
    }
    // if (isMindMapCommand.current && data.editType !== AIEditType.ContinueWrite) {
    //   isMindMapCommand.current = false;
    // }

    if (data.editType === AIEditType.Bitable) {
      data.editType = AIEditType.Bitable_Other;
    } else if (data.editType === AIEditType.MindMap) {
      data.editType = AIEditType.MindMap_Other;
    }
    if (isAiBitable.has(data.editType)) {
      isBitableCommand.current = true;
    } else if (isAiMindMap.has(data.editType)) {
      isMindMapCommand.current = true;
    }
    // 思维导图默认不显示输入框但选中翻译成其他语言的选项需要显示
    if (!showInputComponent && data.editType === AIEditType.OtherLanguage) {
      setShowInputComponent(true);
      setTimeout(() => {
        focusInput();
      }, 0);
    } else {
      setShowInputComponent(showInput);
    }

    handleEditType(data.editType);
  };
  // 为了去掉思维掉图第二次显示的继续写作
  let removeContinueWrite = false;
  if (isMindMapCommand.current && scene === AIEditorScene.PageContentWroteByAI) {
    removeContinueWrite = true;
  }
  let listViewRender;
  if (!isMindMapCommand.current && !isBitableCommand.current) {
    listViewRender = getCustomAIPromps(scene, onItemClick) as ListItem<any>[];
  }
  if (!listViewRender) {
    listViewRender = getAIMenuItems({
      scene,
      onItemClick,
      selectedBlockIds,
      isBitable,
      isMindMap: isMindMap || removeContinueWrite,
      isBitablePage,
      isDescription,
      editTypeRef,
    }) as ListItem<any>[];
  }

  const store = usePromptStore.getState();
  if (scene === AIEditorScene.PageEmpty) store.editPrompts;
  listViewRender.unshift({
    type: ListItemType.CUSTOM_RENDER,
    render: () => <QuickSetting model={model} />,
  });
  const menuSize = listViewRender.filter((item) => item.type !== ListItemType.LINE).length;

  useEffect(() => {
    const isShow = !placeholder && !prompt && !isWaitingResult && menuSize > 0;
    void sleep(isShow ? 100 : 0).then(() => {
      setShowListView(isShow);
    });
  }, [isWaitingResult, menuSize, prompt, placeholder]);

  const getSelectedContent = () => {
    const { blocks } = getState();
    const pageBlock = blocks[pageId];

    let content = '';
    if (isBitable) {
      if (
        isDescription &&
        (scene === AIEditorScene.TextSelected || scene === AIEditorScene.TextSelectedAndWroteByAI)
      ) {
        content = selectedText.current;
      } else {
        content = getBitableContent(blockId);
      }
    } else if (
      scene === AIEditorScene.PageHasContent ||
      scene === AIEditorScene.PageContentWroteByAI // 可能 retry
    ) {
      content = getBlockIdsContent(pageBlock?.subNodes ?? []);
    } else if (
      // 翻译 选中的块或者选中的文本
      scene === AIEditorScene.BlockSelected ||
      scene === AIEditorScene.BlockSelectedAndWroteByAI || // 可能 retry
      scene === AIEditorScene.TextSelected ||
      scene === AIEditorScene.TextSelectedAndWroteByAI // 可能 retry
    ) {
      if (isMindMap) {
        // 如果是选中了root节点,仅把root节点的数据塞到content
        if (cache.ui.selectedBlocks[0]?.blockId === pageId) {
          content = segmentsToText(pageBlock?.data.segments);
          return content;
        }
      }
      content = selectedText.current;
    }

    return content;
  };

  const removeTempPage = () => {
    if (tempPageId.current) {
      removeBlock(tempPageId.current, true);
      setShowTempPage(false);
      tempPageId.current = '';
    }
  };
  const handleAIMessage = (parsedStr: string, isEndWrap = false) => {
    let strPieces: string[] = [parsedStr];
    // 字符串中间有换行时需要处理
    if (parsedStr.length !== 1 && parsedStr.includes('\n')) {
      strPieces = [];
      parsedStr.split('\n').forEach((str: string, index: number) => {
        if (index !== 0) {
          strPieces.push('\n', str);
        } else {
          strPieces.push(str);
        }
      });
    }
    // 由于本人能力不足，无法很好的驾驭一些flag，只能这么干了
    let useMindMap = false;
    if (isMindMapCommand.current) {
      // 只有生成思维导图或者继续写作才把useMindMap设置为true
      if (
        (editTypeRef.current && isAiMindMap.has(editTypeRef.current)) ||
        editTypeRef.current === AIEditType.ContinueWrite
      ) {
        useMindMap = true;
      }
      if (editTypeRef.current && translateEditType.has(editTypeRef.current)) {
        useMindMap = true;
      }
    }
    for (const str of strPieces) {
      handleStrPiece({
        str,
        pageId,
        scene,
        tempPageId: tempPageId.current,
        currentBlockId,
        allLine,
        popper,
        isInRight,
        resultContainer,
        modalContainer,
        uploadFile,
        useCollection: isBitable || isBitableCommand.current,
        useMindMap,
        isEndWrap,
        codeStartLineNum,
        mindNodeTypes,
        editTypeRef,
      });
    }
  };

  const onAIMessageEnd = () => {
    const lastLine = last(allLine.current) ?? '';
    if (!lastLine.endsWith('\n')) {
      const isCodeLine = lastLine.startsWith('```');
      const isTableLine = lastLine.startsWith('|');
      const isImageLine = lastLine.startsWith('![');
      if (isCodeLine || isTableLine || isImageLine) {
        handleAIMessage('\n', true);
      }
    }

    // 提交最后一个 block
    submitCurrBlock();
    setIsWaiting(false);
    setPrompt('');
    setPlaceHolder('');

    switch (scene) {
      case AIEditorScene.PageEmpty:
      case AIEditorScene.PageHasContent:
        setEditScene(AIEditorScene.PageContentWroteByAI);
        break;
      case AIEditorScene.TextSelected:
        setEditScene(AIEditorScene.TextSelectedAndWroteByAI);
        break;
      case AIEditorScene.BlockSelected:
        if (isMindMapCommand.current && editTypeRef.current === AIEditType.ContinueWrite) {
          // 他喵的特殊处理
          setEditScene(AIEditorScene.PageContentWroteByAI);
        } else {
          setEditScene(AIEditorScene.BlockSelectedAndWroteByAI);
        }
        break;
      default:
        break;
    }
  };

  const addEmptyBlock = (parentId: string, opt: { after?: string; ignoreOP?: boolean }) => {
    return addBlock(
      { type: BlockType.TEXTAREA, data: { segments: [] } },
      { parentId, after: opt.after },
      opt.ignoreOP
    );
  };

  const updateWritingBlockId = () => {
    codeStartLineNum.current = -Infinity;
    switch (scene) {
      case AIEditorScene.PageEmpty:
        currentBlockId.current = blockId;
        break;
      case AIEditorScene.PageHasContent: {
        if (isMindMapCommand.current) {
          // 思维导图不需要创建空块
          break;
        }
        const { blocks } = getState();
        const text = segmentsToText(blocks[blockId]?.data.segments);
        if (text.trim()) {
          currentBlockId.current = addEmptyBlock(pageId, { after: blockId });
        } else {
          currentBlockId.current = blockId;
        }
        break;
      }
      case AIEditorScene.BlockSelected:
      case AIEditorScene.TextSelected:
      case AIEditorScene.BlockSelectedAndWroteByAI:
      case AIEditorScene.TextSelectedAndWroteByAI: {
        // 思维导图继续写作不需要展示tempPage
        if (isMindMapCommand.current && editTypeRef.current === AIEditType.ContinueWrite) return;

        const tempPage = cache.blocks[tempPageId.current];
        if (!tempPage) {
          tempPageId.current = addBlock(
            {
              type: BlockType.PAGE,
              hidden: true,
            },
            {
              parentId: pageId,
            },
            true
          );
          setShowTempPage(true);
          if (isMindMapCommand.current) {
            const block = cache.blocks[blockId];
            if (!block) {
              return;
            }
            mindNodeTypes.current = [getNodeType(block)];
            currentBlockId.current = tempPageId.current;
          }

          currentBlockId.current = addEmptyBlock(tempPageId.current, { ignoreOP: true });
          return;
        }

        const { blocks } = getState();
        const text = segmentsToText(blocks[currentBlockId.current]?.data.segments);
        if (text.trim()) {
          currentBlockId.current = addEmptyBlock(tempPageId.current, {
            after: currentBlockId.current,
            ignoreOP: true,
          });
        }
        break;
      }
      case AIEditorScene.PageContentWroteByAI: {
        const { blocks } = getState();
        const text = segmentsToText(blocks[currentBlockId.current]?.data.segments);
        if (text.trim()) {
          currentBlockId.current = addEmptyBlock(pageId, {
            after: currentBlockId.current,
          });
        }
        break;
      }

      default:
        break;
    }
  };

  const fetchAIResult = async (body?: Record<string, string>) => {
    setIsWaiting(true);

    // if (location.origin.includes('localhost')) {
    //   updateWritingBlockId();
    //   const len = mockData.length;
    //   let i = 0;
    //   while (i <= len) {
    //     if (i === len) {
    //       onAIMessageEnd();
    //       break;
    //     }
    //     const data = mockData[i];
    //     if (!data) break;
    //     i++;
    //     await sleep(200);
    //     handleAIMessage(data);
    //   }
    //   return;
    // }

    try {
      const headers: any = { 'Content-Type': 'application/json' };

      const token = getToken();
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      cancelRequest.current = new AbortController();
      const isCustom = body?.['type'] === 'custom';
      let response: Response;

      if (isCustom && !isBuildIn()) {
        const promptText = body?.['selectedText'];
        if (!promptText) return;
        const ret = customPrompt.current?.replaceAll('{input}', promptText);
        const spaceId = getCurrentSpaceId();
        response = await fetch(`/api/chat/${spaceId}/ask`, {
          signal: cancelRequest.current.signal,
          method: 'POST',
          headers,
          body: JSON.stringify({ prompt: ret }),
        });
      } else {
        response = await fetch(isBuildIn() ? '/api/chat/buildin' : '/api/completions', {
          signal: cancelRequest.current.signal,
          method: 'POST',
          headers,
          body: JSON.stringify({ ...body, spaceId: currentSpace.uuid, blockId }),
        });
      }

      if (!response.ok) return;

      const res = response.body;
      if (!res) return;

      const reader = res.getReader();
      const decoder = new TextDecoder();

      updateWritingBlockId();

      // 存档数据用以兼容请求没有正常返回时，上一次的返回结果在继续写作的时候还能作为参数传递
      if (allLine.current.join('').trim()) {
        archiveResult.current = allLine.current;
      }
      allLine.current = [];
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          onAIMessageEnd();
          break;
        }

        const chunkValue = decoder.decode(value);
        try {
          const errorObj = JSON.parse(chunkValue);
          if (errorObj.code) {
            setIsWaiting(false);
            if (errorObj.code === 1000) {
              closeSelf();
              openUpgradeAiModal({
                isFrom: OpenSettingFrom.ai_max,
              });
            } else {
              submitCurrBlock();
              message.warning(chunkValue);
            }
            break;
          }
        } catch {
          //
        }

        const parsedStr = parseAIMessage(chunkValue);
        // if (!location.origin.includes('flowus.cn')) {
        //   // eslint-disable-next-line no-console
        //   console.log(chunkValue, parsedStr);
        // }

        handleAIMessage(parsedStr);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const submitPrompt = () => {
    if (!prompt || !prompt.trim()) return;

    const { blocks } = getState();
    const pageBlock = blocks[pageId];
    if (!pageBlock) return;
    const pageTitle = segmentsToText(pageBlock.data.segments);

    let body: Record<string, string>;
    const isTranslateOtherLanguage =
      editTypeRef.current === AIEditType.OtherLanguage && prompt.startsWith('把当前语言翻译成');

    if (isBitableCommand.current || isMindMapCommand.current) {
      body = {
        type: editTypeRef.current ?? 'write',
        topic: fixPrompt(prompt, editTypeRef.current ?? AIEditType.Bitable),
        selectedText: getSelectedContent(),
      };
    } else if (isTranslateOtherLanguage) {
      body = {
        type: 'edit',
        prompt,
        selectedText: getSelectedContent(),
      };
    } else if (
      editTypeRef.current === AIEditType.OtherCodeLanguage &&
      prompt.startsWith('把当前代码改写成')
    ) {
      body = {
        type: 'rewriting',
        selectedText: getSelectedContent(),
        language: prompt.split('把当前代码改写成：')?.[1] ?? '',
      };
    } else if (
      scene === AIEditorScene.BlockSelected ||
      scene === AIEditorScene.TextSelected ||
      scene === AIEditorScene.BlockSelectedAndWroteByAI ||
      scene === AIEditorScene.TextSelectedAndWroteByAI
    ) {
      removeTempPage();
      if (isAiBitable) {
        body = { type: 'edit', prompt, selectedText: getSelectedContent(), pageTitle };
      } else {
        body = { type: 'edit', prompt, selectedText: selectedText.current, pageTitle };
      }
    } else {
      const specialWrite = SpecialWriteType.find((item) => {
        const itemPrompt = getEditTypeMapPrompt(item)?.prompt;
        if (!itemPrompt) return false;
        return prompt.startsWith(itemPrompt);
      });

      if (specialWrite) {
        body = {
          type: getEditTypeMapPrompt(specialWrite)?.type ?? 'write',
          topic: prompt,
        };
      } else {
        body = { type: 'write', prompt };
      }
    }
    // 最后的处理
    if (customPrompt.current) {
      body = { type: 'custom', selectedText: prompt };
    }

    void fetchAIResult(body);
  };

  const renderTagList = () => {
    if (isWaitingResult || !isBitableCommand.current) return;
    const promptList = getEditTypeMapPrompt(editTypeRef.current ?? '')?.promptList;
    if (!promptList) return;

    return (
      <div className="pl-8 h-[30px] py-[5px] mb-[5px]">
        {promptList.map((item) => {
          return (
            <span
              key={item.title}
              className="px-1.5 bg-[#ECEDED] text-grey text-t2 mr-1.5 cursor-pointer py-0.5 rounded-sm"
              onClick={() => {
                setPrompt(item.placeholder);
                focusInput();
              }}
            >
              {item.title}
            </span>
          );
        })}
      </div>
    );
  };
  listViewRender = listViewRender.map((v) => {
    if (v.type === ListItemType.OPERATION) {
      v.data.iconClassName = 'text-ai_color';
    }
    return v;
  });
  return (
    <div
      className={cx('ai-editor', showInputComponent ? 'min-w-[400px]' : '')}
      style={{ width: showInputComponent ? pageContentWidth : undefined }}
      ref={modalContainer}
    >
      <Tooltip
        visible={showListView && !isWaitingResult}
        interactive
        offset={[0, 2]}
        placement="bottom-start"
        theme="none"
        animation="shift-away"
        maxWidth={'unset'}
        className="next-modal w-full block"
        popupClass="next-modal"
        popup={
          <ListView
            className="py-2 w-72 max-w-[calc(100vw-24px)] overflow-y-auto max-h-[56vh]"
            items={listViewRender}
            onItemClick={onItemClick}
          />
        }
      >
        {showTempPage && (
          <div
            className="p-2 pb-0 whitespace-pre-wrap text-t2 max-h-[40vh] overflow-auto"
            ref={resultContainer}
          >
            <PageSceneContext.Provider value={PageScene.PAGE_LITE_PREVIEW}>
              <PageScrollRefContext.Provider value={resultContainer}>
                <BlockEditor uuid={tempPageId.current} readonly={true} />
              </PageScrollRefContext.Provider>
            </PageSceneContext.Provider>
          </div>
        )}

        {showInputComponent && (
          <div className="flex border border-ai_color rounded py-1.5 px-2 items-center">
            <Icon
              name="IcAiFill"
              className={cx(
                'mr-2 self-start cursor-pointer text-ai_color',
                !isWaitingResult && 'mt-0.5'
              )}
              size="middle"
            />

            {isWaitingResult && (
              <>
                <span className="text-t2 text-ai_color">{PRODUCT_NAME} 正在写作...</span>
                <Icon className="animate-spin text-grey4" name="IcNaviLoading" size="middle" />
                <span className="text-t2 text-grey4 ml-auto cursor-pointer" onClick={stopWriting}>
                  停止 ESC
                </span>
              </>
            )}

            {!isWaitingResult && (
              <>
                <AutoHeightTextArea
                  ref={inputRef}
                  autoFocus
                  singleLine={false}
                  placeholder={placeholder || `告诉 ${PRODUCT_NAME} 写什么...`}
                  boxClassName="flex-grow"
                  fontClassName="text-t2"
                  onKeyDown={(event) => {
                    event.stopPropagation();
                    event.nativeEvent.stopPropagation();
                    if (isHotkey('esc')(event)) {
                      closeInput();
                    }
                    if (isHotkey('Space')(event)) {
                      if (prompt === undefined) {
                        closeInput();
                        const editorModel = getEditorModel(blockId);
                        if (editorModel) {
                          editorModel.performChange((ctx) => {
                            ctx.insert(' ');
                          });
                          setTimeout(() => {
                            editorModel.performChange((ctx) => {
                              ctx.select(editorModel.content.length);
                            });
                          }, 100);
                          void editorModel.requestFocus();
                        }
                      }
                    }

                    if (event.nativeEvent.isComposing) return;
                    if (isHotkey('enter')(event)) {
                      submitPrompt();
                    }

                    if (!prompt && isHotkey('Backspace')(event)) {
                      closeInput();
                    }
                  }}
                  value={prompt}
                  onChange={(event) => {
                    const node = event.target as HTMLTextAreaElement | null;
                    if (!node) return;
                    setPrompt(node.value);
                  }}
                />

                <Icon
                  name={'IcSendToAi'}
                  onClick={() => submitPrompt()}
                  size="large"
                  className={cx(
                    'text-grey4 self-end cursor-pointer justify-end',
                    prompt && 'text-ai_color'
                  )}
                />
              </>
            )}
          </div>
        )}

        {scene === AIEditorScene.PageEmpty && renderTagList()}
      </Tooltip>
    </div>
  );
};

const getCustomAIPromps = (scene: AIEditorScene, onItemClick: (item: any) => void) => {
  const store = usePromptStore.getState();
  switch (scene) {
    case AIEditorScene.BlockSelected:
    case AIEditorScene.TextSelected: {
      const items = store.editPrompts.map((v) => {
        const prompt = store.records[v];
        return {
          type: ListItemType.OPERATION,
          isHidden: prompt?.hidden,
          data: {
            icon: prompt?.icon || 'IcEdit',
            title: prompt?.name || (prompt?.subNodes ? '未命名指令目录' : '未命名指令'),
            uuid: v,
            hasArrow: !!prompt?.subNodes,
            prompt: prompt?.minimax,
            editType: prompt?.type,
            renderSubMenu: prompt?.subNodes
              ? () => <SubMenu subNodes={prompt?.subNodes ?? []} onItemClick={onItemClick} />
              : undefined,
          },
        };
      });
      return items;
    }
    case AIEditorScene.PageEmpty: {
      const items = store.emptyWithPrompts.map((v) => {
        const prompt = store.records[v];
        return {
          type: ListItemType.OPERATION,
          isHidden: prompt?.hidden,
          data: {
            icon: prompt?.icon || 'IcEdit',
            title: prompt?.name || (prompt?.subNodes ? '未命名指令目录' : '未命名指令'),
            uuid: v,
            hasArrow: !!prompt?.subNodes,
            prompt: prompt?.minimax,
            editType: prompt?.type,
            renderSubMenu: prompt?.subNodes
              ? () => <SubMenu subNodes={prompt?.subNodes ?? []} onItemClick={onItemClick} />
              : undefined,
          },
        };
      });
      return items;
    }
    default:
  }
};

interface SubMenuProps {
  subNodes: string[];
  onItemClick: (item: any) => void;
}
const SubMenu: FC<SubMenuProps> = (props) => {
  const store = usePromptStore.getState();
  const items = props.subNodes.map((v) => {
    const prompt = store.records[v];
    return {
      type: ListItemType.OPERATION,
      isHidden: prompt?.hidden,
      data: {
        icon: prompt?.icon || 'IcEdit',
        iconClassName: 'text-ai_color',
        title: prompt?.name || (prompt?.subNodes ? '未命名指令目录' : '未命名指令'),
        uuid: v,
        editType: prompt?.type,
        hasArrow: !!prompt?.subNodes,
        placeHolder: prompt?.placeholder,
        prompt: prompt?.minimax,
      },
    };
  });
  return (
    <ListView
      className={listViewNormalClassName}
      keyDownPriority={PRIORITY_DIALOG + 1}
      items={items}
      onItemClick={props.onItemClick}
    />
  );
};
