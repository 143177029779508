import { cx } from '@flowus/common/cx';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useBitable } from 'src/bitable/context';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import type { CellViewProps } from './types';
import { arrayToStyle } from '@flowus/common';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import type { formula } from '@flowus/formula';
import { TagItem } from './select/tag-item';

export const FormulaStyleTextValue: FC<CellViewProps> = ({ className, recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const segments = useObservableStore(
    (state) => {
      const { blocks } = state;
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (schema?.type === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(collectionId, state);
        const ret = formulaTool.getValue(recordId, propertyId) as string[];
        return arrayToStyle(ret);
      }
    },
    [recordId, propertyId, collectionId]
  );

  if (!segments) return null;

  return (
    <div className="p-2 w-full group-scope">
      <RichText
        className={cx('leading-[20px]', className)}
        segments={segments}
        interactable={true}
      />
    </div>
  );
};

export const FormulaSelectValue: FC<CellViewProps> = ({ className, recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const select = useObservableStore(
    (state) => {
      const { blocks } = state;
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (schema?.type === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(collectionId, state);
        return formulaTool.getValue(recordId, propertyId) as formula.Select;
      }
    },
    [recordId, propertyId, collectionId]
  );

  if (!select?.name) return null;

  return (
    <div className="p-[5px] w-full">
      <TagItem
        className="flex-shrink-0 max-w-[calc(100%-6px)] m-[3px]"
        color={select.color}
        value={select.name}
        label={select.name}
      />
    </div>
  );
};
