import { QASearchSource } from '@next-space/fe-api-idl';
import type { QaBlockResource } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { emitter, EmitterEvent } from '../../utils/emitter';
import { checkLimit } from './ai-limit-store';
import { fetchLiteChatByParentId } from './store';
import { ConversationTools } from './store-tools';
import { DEFAULT_REFERENCE_OPTIONS } from './types';

interface OpenLiteChatOptions {
  parentId: string;
  keywords: string;
  spaceId: string;
  searchBlocks?: QaBlockResource[];
  qaSearchSource?: QASearchSource;
  onOpen: (params?: { conversationId: string }) => void;
}

export const useOpenLiteConversation = () => {
  return useCallback(
    async ({
      parentId,
      keywords,
      searchBlocks = [],
      qaSearchSource = DEFAULT_REFERENCE_OPTIONS,
      onOpen,
      spaceId,
    }: OpenLiteChatOptions) => {
      if (!checkLimit(spaceId)) {
        emitter.emit(EmitterEvent.chatAiLimit);
        throw new Error('ai limit exceeded');
      }
      onOpen?.();

      if (searchBlocks.length) {
        qaSearchSource = QASearchSource.NONE;
      }

      let conversationId = await fetchLiteChatByParentId(parentId);

      const defaultConversationEdit = new ConversationTools(parentId);
      const { qaQueryOption } = defaultConversationEdit.getConversationEdit();

      defaultConversationEdit.updateQaQueryOption({
        ...qaQueryOption,
        searchBlocks,
        qaSearchSource,
      });

      const updatedQaQueryOption = {
        ...qaQueryOption,
        searchBlocks,
        qaSearchSource,
      };

      // 如果存在，就往里直接加新的问题
      if (conversationId) {
        onOpen({ conversationId });
        const conversationTools = new ConversationTools(conversationId);
        const { qaQueryOption } = conversationTools.getConversationEdit();

        await conversationTools.createQuestion({
          keywords,
          qaQueryOption: {
            ...qaQueryOption,
            searchBlocks,
            qaSearchSource,
          },
          spaceId,
        });
      } else {
        const conversationTools = new ConversationTools(parentId);
        conversationId = await conversationTools.createConversation({
          keywords,
          qaQueryOption: updatedQaQueryOption,
          parentId,
          spaceId,
        });
      }

      onOpen({ conversationId });
    },
    []
  );
};
