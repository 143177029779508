// Track all running streams and their controllers
const runningStreams = new Map<string, AbortController>();

// Utility function to make real API streaming call
export const streamAIResponse = async (
  chatId: string,
  spaceId: string,
  onChunk: (chunk: string) => void
) => {
  // Create new AbortController for this stream
  const controller = new AbortController();
  runningStreams.set(chatId, controller);

  try {
    const response = await fetch(`/api/chat/${spaceId}/qa`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chatId,
      }),
      signal: controller.signal,
    });

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('Response body is not readable');
    }

    const decoder = new TextDecoder();
    let buffer = '';
    let isDone = false;

    try {
      while (!isDone) {
        const { done, value } = await reader.read();
        if (done) {
          isDone = true;
          break;
        }

        buffer += decoder.decode(value, { stream: true });

        // Process line by line
        const lines = buffer.split('\n');
        buffer = lines.pop() || ''; // Keep the last incomplete line in buffer

        for (const line of lines) {
          if (!line.trim() || !line.startsWith('data: ')) continue;

          try {
            const data = JSON.parse(line.slice(6));
            if (data && typeof data === 'object' && 'content' in data) {
              onChunk(data.content);
            }
          } catch (e) {
            // ignore parse errors
          }
        }
      }

      // Process any remaining data in buffer
      if (buffer.trim() && buffer.startsWith('data: ')) {
        try {
          const data = JSON.parse(buffer.slice(6));
          if (data && typeof data === 'object' && 'content' in data) {
            onChunk(data.content);
          }
        } catch (e) {
          // ignore parse errors
        }
      }
    } finally {
      reader.releaseLock();
    }
  } catch (error: any) {
    if (error.name === 'AbortError') {
      console.log('Stream was aborted');
    } else {
      console.error('Error in AI stream:', error);
      throw error;
    }
  } finally {
    // Clean up
    runningStreams.delete(chatId);
  }
};

// Stop a specific stream by ID
export const stopStream = (id: string) => {
  const controller = runningStreams.get(id);
  if (controller) {
    controller.abort();
    runningStreams.delete(id);
  }
};
